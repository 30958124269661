import { transform } from 'lodash-es'

export const API_URL = process.env.REACT_APP_API_URL

export const locales = [
  { code: 'uk', file: 'uk.js', name: 'Українська' },
  { code: 'en', file: 'en.js', name: 'English' }
]

export const LOADING_STATES = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  IDLE: 'IDLE'
}

export const SURVEY_QUESTION_USER_COLUMNS = [
  { value: '', label: '----' },
  { value: 'sex', label: 'Пол' },
  { value: 'birthday', label: 'День рождения' },
  { value: 'first_name', label: 'Имя' },
  { value: 'city', label: 'Город' }
]

export const EMPTY_TRANSLATION = {
  uk: '',
  en: ''
}

export const EMPTY_ARTICLE_CONTENT = {
  uk: [
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ],
  en: null
}

export const EMPTY_MARKER = {
  title: { ...EMPTY_TRANSLATION },
  description: { ...EMPTY_TRANSLATION },
  international_code: 'none',
  units_ids: [],
  marker_category_id: ''
}

export const EMPTY_ARTICLE = {
  title: { ...EMPTY_TRANSLATION },
  content: { ...EMPTY_TRANSLATION },
  article_category_id: null,
  article_tag_ids: [],
  type: ''
}

export const EMPTY_QUESTION_OPTION = {
  title: EMPTY_TRANSLATION
}

export const EMPTY_ANALYSES_CATEGORY = {
  title: EMPTY_TRANSLATION
}

export const EMPTY_ARTICLE_CATEGORY = {
  title: EMPTY_TRANSLATION
}

export const EMPTY_ANALYSES_UNIT = {
  title: EMPTY_TRANSLATION
}

export const TITLE_ERROR = 'Title is a required field'

export const viewModes = {
  edit: 'edit',
  view: 'view'
}

export const DATE_FORMATS = {
  DATE_AND_TIME: 'dd/MM/yy, H:mm',
  DATE: 'dd/MM/yy'
}

export const uuidRegExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const PIXEL_SIZE_FOR_BUTTON_ICONS_BY_BUTTON_SIZE = {
  xl: 22,
  lg: 20,
  m: 17,
  default: 16,
  sm: 14,
  xs: 12
}

export const ENTITY_CHANGEMENT_TYPES = {
  created: 'created',
  updated: 'updated',
  deleted: 'deleted'
}

export const DRAFT_NAME = 'draft'

export const ORDER_NAME = 'order'

export const mathConditionMap = {
  eq: 'eq',
  neq: 'neq',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  in: 'in',
  nin: 'nin'
}

export const dateFormatMap = {
  sortableDateTime: 'yyyy.MM.dd, HH:mm',
  dateTime: 'dd.MM.yy, HH:mm',
  date: 'dd/MM/yy',
  sortableDate: 'yyyy.MM.dd',
  dateLYear: 'dd/MM/yyyy',
  dateDotDLYear: 'dd.MM.yyyy'
}

export const emptyFreezedArray = Object.freeze([])

export const labelByMathCondition = {
  eq: <span>&#x3d;</span>,
  neq: <span>&#x2260;</span>,
  gt: <span>&#x3e;</span>,
  gte: <span>&#x22DD;</span>,
  lt: <span>&#x3c;</span>,
  lte: <span>&#x22DC;</span>,
  in: <span>&#x2208;</span>,
  nin: <span>&#x2209;</span>
}

export const optionByMathCondition = transform(
  labelByMathCondition,
  (acc, label, value) =>
    (acc[value] = {
      label,
      value
    }),
  {}
)
