import { setEntityChangementListeners } from './utils'

export default ({ entity, config = [], dependenciesConfig = [] }) => {
  if (!entity) {
    throw 'wrong changement config'
  }
  return {
    init: () => {
      for (const { changements, listener } of config) {
        setEntityChangementListeners(entity, changements, {
          listener
        })
      }
      if (dependenciesConfig) {
        for (const {
          entities,
          changements,
          listener,
          shouldEmitUpdatedEvent,
          processData
        } of dependenciesConfig) {
          for (const dependencyEntity of entities) {
            setEntityChangementListeners(dependencyEntity, changements, {
              listener,
              emitUpdatedEventOptions: {
                enabled: !!shouldEmitUpdatedEvent,
                entity,
                processData
              }
            })
          }
        }
      }
    }
  }
}
