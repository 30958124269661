import { produce } from 'immer'
import { serialize } from 'object-to-formdata'

import recGroupBasisRepository from '@features/recGroupBasis/repository'
import { fromDefaultValues } from '@utils'

export const serializeCData = (data) =>
  serialize(
    produce(data, (data) => {
      const groupImages = []
      const groups = data.groups
        .filter((i) => !!i.imageFile)
        .forEach(({ imageFile }, index) => {
          groups[index].imageFile = index
          groupImages.push(imageFile)
        })
      data.groupImages = groupImages
    }),
    {
      noFilesWithArrayNotation: false,
      indices: true
    }
  )

export const parseCFormData = ({ groups, ...rest }) => ({
  ...rest,
  groups: groups.map(recGroupBasisRepository.parceCEDataToData)
})

export const makeShortTitle = (data) =>
  `${data.title.uk} (груп: ${data.groups.length})`

export const makeGroupShortTitle = (data) =>
  `${data.order + 1}. ${data.title.uk} (рекомендацій: ${
    data.recommendations.length
  }, затримка днів: ${data.delayD})`

export function createDataTree({
  stage,
  groupsByStageId,
  recommendationsByGroupId,
  todosByRecommendationId,
  intervalsByTodoId
}) {
  return {
    ...stage,
    groups:
      groupsByStageId[stage.id]?.map((group) =>
        recGroupBasisRepository.createDataTree({
          group,
          recommendationsByGroupId,
          todosByRecommendationId,
          intervalsByTodoId
        })
      ) ?? []
  }
}

export const defaultGroupEValues = {
  ...recGroupBasisRepository.defaultEValues,
  delayD: 0
}

export const defaultGroupCValues = {
  ...recGroupBasisRepository.defaultCValues,
  delayD: 0
}

export const defaultEValues = {
  title: {
    en: '',
    uk: ''
  },
  descriptionArticleId: null
}

export const defaultCValues = {
  ...defaultEValues,
  groups: []
}

export const transformToCFormData = (
  data,
  { defaultValues = defaultCValues } = {}
) => {
  const newData = fromDefaultValues(data, defaultValues)
  return {
    ...newData,
    groups:
      data.groups?.map((data) =>
        recGroupBasisRepository.transformToCFormData(data, {
          defaultValues: defaultGroupCValues,
          shouldTransformImage: false
        })
      ) ?? []
  }
}

const recoveryStageBasisRepository = {
  defaultCValues,
  defaultEValues,
  serializeCData,
  createDataTree,
  parseCFormData,
  makeShortTitle,
  makeGroupShortTitle,
  transformToCFormData
}

export default recoveryStageBasisRepository
