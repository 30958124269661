import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import { getEntityChangementMessage } from '@infrastructure/entityChangement/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import toast from '@infrastructure/toast/toast'

import surveyRQKeys from '../reactQuery/surveyRQKeys'

export default createEntityChangementConfig2({
  entity: 'survey',
  config: [
    {
      changements: ['created', 'updated', 'deleted'],
      listener: ({ name, type }) => {
        toast.info({
          title: getEntityChangementMessage(name, type)
        })
        queryClient.invalidateQueries(surveyRQKeys.list())
      }
    },
    {
      changements: ['updated'],
      listener: ({ meta: { id } }) => {
        queryClient.invalidateQueries(surveyRQKeys.detail(id))
      }
    },
    {
      changements: ['deleted'],
      listener: ({ meta: { id } }) => {
        queryClient.removeQueries(surveyRQKeys.detail(id))
      }
    }
  ]
})
