import { fromDefaultValues } from '@utils'

import formulaExpressionHelpers from './formulaExpressionHelpers'
import formulaVariableHelpers from './formulaVariableHelpers'

const entityName = 'formula'

const baseUrl = '/formulas'

const creationUrl = `${baseUrl}/create`

const getDetailUrl = (id) => `${baseUrl}/${id}`

const defaultData = {
  id: undefined,
  title: '',
  variables: [],
  expressions: [],
  minSucceededExpressions: null,
  reasons: '',
  consequences: ''
}

const transformToFormData = (data = {}) => {
  const { variables, expressions, ...rest } = fromDefaultValues(
    data,
    defaultData
  )
  return {
    ...rest,
    variables: variables.map(formulaVariableHelpers.transformToFormData),
    expressions: expressions.map(formulaExpressionHelpers.transformToFormData)
  }
}

const tranformToPreviewData = ({ variables, ...rest }, markers, units) => ({
  ...rest,
  variables: variables.map((item) =>
    formulaVariableHelpers.tranformToPreviewData(item, markers, units)
  )
})

export default {
  entityName,
  creationUrl,
  getDetailUrl,
  transformToFormData,
  tranformToPreviewData
}
