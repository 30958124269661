import { format } from 'date-fns'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'

import { DATE_FORMATS } from '@common/constants'
import { byId, getDataProp } from '@common/utils'
import { useSurveyPreviewsQ } from '@features/surveySection/survey/reactQuery'

import {
  create,
  getOverviews,
  getPreviews,
  getSurveyResultsById,
  name
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  overviews: ({ query = {} } = {}) => [...keys.lists(), 'overviews', query],
  previews: ({ query = {} } = {}) => [...keys.lists(), 'previews', query],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id],
  surveyAnswers: (id) => [...keys.all, id, 'results']
}

export const useOverviewsQ = ({ query, ...options } = {}) =>
  useQuery({
    queryKey: keys.overviews({ query }),
    queryFn: () => getOverviews({ query }).then(getDataProp),
    ...options
  })

export const usePreviewsQ = ({ query, ...options } = {}) =>
  useQuery({
    queryKey: keys.previews({ query }),
    queryFn: () => getPreviews({ query }).then(getDataProp),
    ...options
  })

export const useGetUserSurveySurveyResultsQ = (id) =>
  useQuery(keys.surveyAnswers(id), () =>
    getSurveyResultsById(id).then(getDataProp)
  )

export const useCreateUserSurveyM = (options) => {
  return useMutation((data) => create(data), options)
}

export const useMakePreviewTitle = () => {
  const { data: surveyById } = useSurveyPreviewsQ({ select: byId })
  return useCallback(
    (data) =>
      `${surveyById ? surveyById[data.surveyId]?.title : '...'} від ${format(
        data.passedAt,
        DATE_FORMATS.DATE
      )}`,
    [surveyById]
  )
}

const userSurveyRQ = {
  useOverviewsQ,
  usePreviewsQ,
  useMakePreviewTitle
}

export default userSurveyRQ
