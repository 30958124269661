import camelcaseKeys from 'camelcase-keys'
import { io } from 'socket.io-client'

import { API_SOCKET_URL, API_URL } from '@common/env'
import socketEntityChangementListener from '@infrastructure/entityChangement/socketEntityChangementListener'

const url = API_SOCKET_URL ?? API_URL

const apiSocket = io(url, {
  transports: ['websocket', 'polling'],
  withCredentials: true,
  autoConnect: false
})

apiSocket.on('connect_error', (err) => {
  if (err.name === 'UnauthorizedError') {
    apiSocket.connect()
  }
})

apiSocket.on('entity_change', (...args) =>
  socketEntityChangementListener(...camelcaseKeys(args, { deep: true }))
)

export default apiSocket
