import formulaChangementConfig from '@features/analysesSection/formula/entityChangement/formulaChangementConfig'
import markerCategoryChangementConfig from '@features/analysesSection/markerCategory/entityChangement/markerCategoryChangementConfig'
import markerGroupChangementConfig from '@features/analysesSection/markerGroups/entityChangement/markerGroupChangementConfig'
import markerUnitChangementConfig from '@features/analysesSection/markerUnit/entityChangement/markerUnitChangementConfig'
import markerChangementConfig from '@features/analysesSection/markers/entityChangement/markerChangementConfig'
import userAnalysesChangementConfig from '@features/analysesSection/userAnalyses/entityChangement/userAnalysesChangementConfig'
import applicationDataChangementConfig from '@features/applicationData/entityChangement/applicationDataChangementConfig'
import articleChangementConfig from '@features/articlesSection/article/entityChangement/articleChangementConfig'
import surveyChangementConfig from '@features/surveySection/survey/changement/surveyChangementConfig'
import userChangementConfig from '@features/user/entityChangement/userChangementConfig'
import userSurveyChangementConfig from '@features/userSurvey/entityChangement/userSurveyChangementConfig'

export default {
  init: () => {
    userAnalysesChangementConfig.init()

    userChangementConfig.init()

    articleChangementConfig.init()

    userSurveyChangementConfig.init()

    markerUnitChangementConfig.init()

    markerCategoryChangementConfig.init()

    markerChangementConfig.init()

    formulaChangementConfig.init()

    markerGroupChangementConfig.init()

    applicationDataChangementConfig.init()

    surveyChangementConfig.init()
  }
}
