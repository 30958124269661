import { round, transform } from 'lodash-es'

import restApi from '@infrastructure/api/restApi'

export const ccyCodeToNumMap = {
  UAH: 980,
  USD: 840,
  EUR: 978
}

export const ccyNumToCodeMap = transform(
  ccyCodeToNumMap,
  (acc, curr, key) => (acc[curr] = key)
)

export const ccyOptions = [
  {
    label: 'UAH',
    value: 980
  }
  // {
  //   label: 'USD',
  //   value: 840
  // },
  // {
  //   label: 'EUR',
  //   value: 978
  // }
]

export const amountToString = (int) => round(int / 100, 2).toString()

export const amountToInt = (str) => round(Number.parseFloat(str) * 100)

export const makePriceTitle = (
  amount,
  ccy,
  { shouldParseIntAmount = true } = {}
) =>
  `${
    shouldParseIntAmount && typeof amount === 'number'
      ? amountToString(amount)
      : amount
  } ${ccyNumToCodeMap[ccy]}`

export const getList = () => restApi.get('/admin/accounting')

const accountingRepository = {
  makePriceTitle,
  amountToInt,
  amountToString
}

export default accountingRepository
