import { lazy } from 'react'
import { CreditCard } from 'react-feather'

import { Routes } from '@infrastructure/navigation/routes'
import verticalNavigation from '@infrastructure/navigation/vertical'

const path = '/accounting'

Routes.push({
  path,
  component: lazy(() => import('../components/AccountingPage')),
  meta: {
    authRoute: true
  }
})

verticalNavigation.financesConfig.children.push({
  id: 'accounting',
  title: 'Accounting',
  icon: <CreditCard size={20} />,
  navLink: path
})
