import { lazy } from 'react'

import { Routes } from '@infrastructure/navigation/routes'
import verticalNavigation from '@infrastructure/navigation/vertical'

import { name } from '../repository'

const basePath = '/todo-prototypes'

Routes.push({
  path: basePath,
  component: lazy(() => import('../components/TodoPrototypesPage')),
  meta: {
    authRoute: true
  }
})

verticalNavigation.prototypesSection.children.push({
  id: name,
  title: 'Щоденні Завдання',
  navLink: basePath
})
