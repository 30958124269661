const formulaRQKeys = {
  all: ['formula'],
  lists: () => [...formulaRQKeys.all, 'lists'],
  list: () => [...formulaRQKeys.lists(), 'list'],
  formData: () => [...formulaRQKeys.all, 'formData'],
  details: () => [...formulaRQKeys.all, 'detail'],
  detail: (id) => [...formulaRQKeys.details(), id]
}

export default formulaRQKeys
