import restApi from '@infrastructure/api/restApi'

export const name = 'surveyAssignmentAutomation'

export const create = (data) =>
  restApi.post('/admin/survey-assignment-automations', data)

export const getOverviews = () =>
  restApi.get(`/admin/survey-assignment-automations`)

export const updateById = (id, data) =>
  restApi.put(`/admin/survey-assignment-automations/${id}`, data)

export const deleteById = (id) =>
  restApi.delete(`/admin/survey-assignment-automations/${id}`)
