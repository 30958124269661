const markerCategoryRQKeys = {
  all: ['markerCategory'],
  lists: () => [...markerCategoryRQKeys.all, 'lists'],
  list: () => [...markerCategoryRQKeys.lists(), 'list'],
  previewList: () => [...markerCategoryRQKeys.lists(), 'preview'],
  details: () => [...markerCategoryRQKeys.all, 'details'],
  detail: (id) => [...markerCategoryRQKeys.list(), id]
}

export default markerCategoryRQKeys
