const markerRQKeys = {
  all: ['markers'],
  lists: () => [...markerRQKeys.all, 'lists'],
  list: () => [...markerRQKeys.lists(), 'list'],
  previewList: () => [...markerRQKeys.lists(), 'preview'],
  details: () => [...markerRQKeys.all, 'detail'],
  detail: (id) => [...markerRQKeys.details(), id]
}

export default markerRQKeys
