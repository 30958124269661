export const markerNormTypeMap = {
  norm: 'norm',
  optimum: 'optimum'
}

export const markerNormTypeOptions = [
  { value: 'norm', label: 'Norm' },
  { value: 'optimum', label: 'Optimum' }
]

export const markerNormEntityName = 'marker_norm'
