import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import { name } from '../repository'

createEntityChangementConfig2({
  entity: name,
  config: [
    {
      changements: ['created', 'updated', 'deleted'],
      listener: () => {
        queryClient.invalidateQueries(keys.all)
      }
    }
  ]
}).init()
