import { serialize } from 'object-to-formdata'

import recoveryStageBasisRepository from '@features/recoveryStageBasis/repository'
import restApi from '@infrastructure/api/restApi'

export const name = 'recoveryStagePrototype'

export const groupName = 'rcvStgProtGroup'

export const recommendationName = 'rcvStgProtGrRecommendation'

export const todoName = 'rcvStgProtGrRecTodo'

export const intervalName = 'rcvStgProtGrRecTodoInterval'

export const getOverviews = ({
  page = undefined,
  pageLimit = undefined
} = {}) =>
  restApi.get('/admin/recovery-stage-prototypes', {
    params: {
      page,
      pageLimit
    }
  })

export const getPreviews = ({
  search = undefined,
  page = undefined,
  pageLimit = undefined
} = {}) =>
  restApi.get('/admin/recovery-stage-prototypes/previews', {
    params: {
      search,
      page,
      pageLimit
    }
  })

export const getDetailById = (id) =>
  restApi.get(`/admin/recovery-stage-prototypes/${id}`)

export const create = (data) =>
  restApi.post(
    '/admin/recovery-stage-prototypes',
    recoveryStageBasisRepository.serializeCData(data)
  )

export const updateById = (id, data) =>
  restApi.put(`/admin/recovery-stage-prototypes/${id}`, data)

export const deleteById = (id) =>
  restApi.delete(`/admin/recovery-stage-prototypes/${id}`)

export const createStgGroup = (id, data) =>
  restApi.post(
    `/admin/recovery-stage-prototypes/${id}/groups`,
    serialize(data, { noFilesWithArrayNotation: false, indices: true })
  )

export const updateGroupById = (id, data) =>
  restApi.put(
    `/admin/recovery-stage-prototypes/groups/${id}`,
    serialize(data, { noFilesWithArrayNotation: false, indices: true })
  )

export const deleteGroupById = (id) =>
  restApi.delete(`/admin/recovery-stage-prototypes/groups/${id}`)

export const reorderStgGroups = (id, data) =>
  restApi.patch(`/admin/recovery-stage-prototypes/${id}/groups/reorder`, data)

export const createGrRecommendation = (id, data) =>
  restApi.post(
    `/admin/recovery-stage-prototypes/groups/${id}/recommendations`,
    data
  )

export const updateRecommendationById = (id, data) =>
  restApi.put(
    `/admin/recovery-stage-prototypes/groups/recommendations/${id}`,
    data
  )

export const deleteRecommendationById = (id) =>
  restApi.delete(
    `/admin/recovery-stage-prototypes/groups/recommendations/${id}`
  )

export const reorderGrRecommendations = (id, data) =>
  restApi.patch(
    `/admin/recovery-stage-prototypes/groups/${id}/recommendations/reorder`,
    data
  )

export const createRecTodo = (id, data) =>
  restApi.post(
    `/admin/recovery-stage-prototypes/groups/recommendations/${id}/todos`,
    data
  )

export const updateTodoById = (id, data) =>
  restApi.put(
    `/admin/recovery-stage-prototypes/groups/recommendations/todos/${id}`,
    data
  )

export const deleteTodoById = (id) =>
  restApi.delete(
    `/admin/recovery-stage-prototypes/groups/recommendations/todos/${id}`
  )

export const createTodoInterval = (id, data) =>
  restApi.post(
    `/admin/recovery-stage-prototypes/groups/recommendations/todos/${id}/intervals`,
    data
  )

export const reorderTodoIntervals = (id, data) =>
  restApi.patch(
    `/admin/recovery-stage-prototypes/groups/recommendations/todos/${id}/intervals/reorder`,
    data
  )

export const updateIntervalById = (id, data) =>
  restApi.put(
    `/admin/recovery-stage-prototypes/groups/recommendations/todos/intervals/${id}`,
    data
  )

export const deleteIntervalById = (id, data) =>
  restApi.delete(
    `/admin/recovery-stage-prototypes/groups/recommendations/todos/intervals/${id}`,
    data
  )

export const defaultCValues = {
  ...recoveryStageBasisRepository.defaultCValues,
  note: ''
}

export const defaultEValues = {
  ...recoveryStageBasisRepository.defaultEValues,
  note: ''
}
