import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import {
  groupName,
  intervalName,
  name,
  recommendationName,
  todoName
} from '../repository'

export default createEntityChangementConfig2({
  entity: name,
  config: [
    {
      changements: ['created', 'updated', 'deleted', 'groupsReordered'],
      listener: () => {
        queryClient.invalidateQueries({
          queryKey: keys.lists()
        })
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: [groupName, recommendationName, todoName, intervalName],
      changements: [
        'created',
        'updated',
        'deleted',
        'recommendationsReordered',
        'intervalsReordered'
      ],
      listener: () => {
        queryClient.invalidateQueries({
          queryKey: keys.lists()
        })
      }
    }
  ]
}).init()
