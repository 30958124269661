import restApi from '@infrastructure/api/restApi'

export const create = (data) => restApi.post('/admin/dose-units', data)

export const getOverviews = () => restApi.get(`/admin/dose-units`)

export const getPreviews = () => restApi.get(`/admin/dose-units/previews`)

export const getDetailById = (id) => restApi.get(`/admin/dose-units/${id}`)

export const updateById = (id, data) =>
  restApi.put(`/admin/dose-units/${id}`, data)

export const deleteById = (id) => restApi.delete(`/admin/dose-units/${id}`)

export const name = 'doseUnit'
