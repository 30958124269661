import { compact, filter, get, groupBy, keyBy, transform } from 'lodash-es'
import { useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import surveyRQKeys from '@features/surveySection/survey/reactQuery/surveyRQKeys'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { createSelector } from '@reduxjs/toolkit'
import { toastApiError } from '@src/utility/apiUtils'

import applicationDataApi from '../service/applicationDataApi'
import applicationDataRQKeys from './applicationDataRQKeys'

const queryFn = () =>
  applicationDataApi
    .getPreviews()
    .then(getDataProp)
    .then((data) => {
      const { surveys } = data
      queryClient.setQueryData(surveyRQKeys.previews(), surveys)
      return data
    })

export const useAppDataQ = (options = undefined) =>
  useQuery(applicationDataRQKeys.previews(), queryFn, {
    ...options,
    onError: toastApiError
  })

export const prefetchApplicationPreviews = (options = undefined) =>
  queryClient.prefetchQuery(applicationDataRQKeys.previews(), queryFn, options)

export const getMarkers = (data) => get(data, 'markers')

export const selectMarkersByCategoryIdMap = createSelector(
  getMarkers,
  (markers) => groupBy(filter(markers, 'categoryId'), 'categoryId')
)

export const selectMarkersByPanelIdMap = createSelector(getMarkers, (markers) =>
  groupBy(filter(markers, 'panelId'), 'panelId')
)

export const selectMarkerByIdMap = createSelector(getMarkers, (markers) =>
  keyBy(markers, 'id')
)

export const getMarkerGroups = (data) => get(data, 'markerGroups')

export const selectMarkerGroupsByCategoryIdMap = createSelector(
  getMarkerGroups,
  (markerGroups) => groupBy(filter(markerGroups, 'categoryId'), 'categoryId')
)

export const selectMarkerGroupsPreviewByCategoryIdMap = createSelector(
  selectMarkerGroupsByCategoryIdMap,
  selectMarkerByIdMap,
  (markerGroupsByCategoryId, markerById) =>
    transform(
      markerGroupsByCategoryId,
      (acc, markerGroups, categoryId) => {
        acc[categoryId] = markerGroups.map(({ markers, ...rest }) => ({
          ...rest,
          markers: markers.map(({ markerId }) => ({
            marker: markerById[markerId]
          }))
        }))
      },
      {}
    )
)

export const selectMarkerCategories = ({ markerCategories }) => markerCategories

export const selectMarkerCategoryMarkersPreviews = createSelector(
  selectMarkersByCategoryIdMap,
  selectMarkerCategories,
  (markersByCategoryId, markerCategories) =>
    markerCategories.map(({ id, ...rest }) => ({
      id,
      markers: markersByCategoryId[id] ?? [],
      ...rest
    }))
)

export const selectMarkerCategoryByIdMap = createSelector(
  selectMarkerCategories,
  (categories) => keyBy(categories, 'id')
)

export const selectMarkerCategoryPreviews = createSelector(
  selectMarkersByCategoryIdMap,
  selectMarkerGroupsPreviewByCategoryIdMap,
  selectMarkerCategories,
  (markersByCategoryId, markerGroupsByCategoryId, markerCategories) =>
    markerCategories.map(({ id, ...rest }) => ({
      id,
      markerGroups: markerGroupsByCategoryId[id] ?? [],
      markers: markersByCategoryId[id] ?? [],
      ...rest
    }))
)

export const selectMarkersAndMarkerGroups = createSelector(
  getMarkers,
  selectMarkerByIdMap,
  getMarkerGroups,
  (markers, markerById, markerGroups) => ({
    markerGroups: markerGroups.map(({ markers, ...rest }) => ({
      markers: markers.map(({ markerId }) => ({
        marker: markerById[markerId]
      })),
      ...rest
    })),
    markers
  })
)

export const selectMarkersWithoutPanel = createSelector(getMarkers, (markers) =>
  markers.filter((item) => !item.panelId)
)

export const selectMarkerWithoutPanelByIdMap = createSelector(
  selectMarkersWithoutPanel,
  (markers) => keyBy(markers, 'id')
)

export const selectMarkerGroupsWithoutPanel = createSelector(
  selectMarkerWithoutPanelByIdMap,
  getMarkerGroups,
  (markerById, markerGroups) =>
    compact(
      markerGroups.map(({ markers, ...rest }) => {
        const fMarkers = markers.filter(
          ({ markerId }) => !!markerById[markerId]
        )
        if (markers.length === 0) {
          return null
        }
        return {
          markers: fMarkers.map(({ markerId }) => ({
            marker: markerById[markerId]
          })),
          ...rest
        }
      })
    )
)

export const selectMarkerPanelMarkersById = createSelector(
  selectMarkersByPanelIdMap,
  (_, id) => id,
  (markersByPanelId, id) => markersByPanelId[id] ?? []
)

export const selectMarkerPanelMarkerGroupsById = createSelector(
  selectMarkerPanelMarkersById,
  getMarkerGroups,
  (markers, markerGroups) => {
    const markerById = keyBy(markers, 'id')
    return compact(
      markerGroups.map(({ markers, ...rest }) => {
        const fMarkers = markers.filter(
          ({ markerId }) => !!markerById[markerId]
        )
        if (markers.length === 0) {
          return null
        }
        return {
          markers: fMarkers.map(({ markerId }) => ({
            marker: markerById[markerId]
          })),
          ...rest
        }
      })
    )
  }
)

export const selectMarkersAndMarkerGroupsWithoutPanel = createSelector(
  selectMarkersWithoutPanel,
  selectMarkerGroupsWithoutPanel,
  (markers, markerGroups) => ({ markers, markerGroups })
)

export const getMarkerPanels = (data) => get(data, 'markerPanels')

export const selectMarkerPanelsWithMarkers = createSelector(
  getMarkerPanels,
  selectMarkersByPanelIdMap,
  (markerPanels, markersByPanelId) =>
    markerPanels.map(({ id, ...rest }) => ({
      id,
      markers: markersByPanelId[id] ?? [],
      ...rest
    }))
)

export const getSurveys = (data) => get(data, 'surveys')

export const selectSurveys = createSelector(getSurveys, (surveys) => surveys)
