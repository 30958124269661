import { camelizeRespDataKeys, customSnakecaseKeys } from '@common/utils'
import restApi from '@infrastructure/api/restApi'

const userAnalysesApi = {
  assignAnalyses: (data) =>
    restApi.post('/admin/user-analyses/assign', customSnakecaseKeys(data)),
  fetchAnalyses: () =>
    restApi.post('/admin/user-analyses/find').then(({ data }) => {
      return data
    }),
  getAnalysesDetailById: (id) =>
    restApi.get(`/admin/user-analyses/${id}`).then(camelizeRespDataKeys),
  updateAnalysesById: (id, fields) =>
    restApi.put(`/admin/user-analyses/${id}`, fields).then(({ data }) => {
      return data
    }),
  deleteAnalysesById: (id) =>
    restApi.delete(`/admin/user-analyses/${id}`).then(({ data }) => {
      return data
    }),
  getOverviewById: (id) =>
    restApi
      .get(`/admin/user-analyses/${id}/overview`)
      .then(camelizeRespDataKeys),
  updateMarkerResults: (id, data) =>
    restApi.put(
      `/admin/user-analyses/${id}/marker-results`,
      customSnakecaseKeys(data)
    ),
  setIsExamined: (id, is) =>
    restApi.patch(`/admin/user-analyses/${id}/examined`, { is }),
  deleteAnalysesMarker: (analysesId, markerId) =>
    restApi
      .delete(`/admin/user-analyses/${analysesId}/markers/${markerId}`)
      .then(({ data }) => {
        return data
      }),
  createAnalysesDocument: (analysesId, formData) =>
    restApi
      .post(`/admin/user-analyses/${analysesId}/`, formData)
      .then(({ data }) => {
        return data
      }),
  viewAnalysesDocument: (analysesId, filename) =>
    restApi
      .patch(`/admin/user-analyses/${analysesId}/documents/${filename}/view`)
      .then(({ data }) => {
        return data
      }),
  deleteAnalysesDocument: (analysesId, filename) =>
    restApi
      .delete(`/admin/user-analyses/${analysesId}/documents/${filename}`)
      .then(({ data }) => {
        return data
      })
}

export default userAnalysesApi
