import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import { entityName } from '../repository'

createEntityChangementConfig2({
  entity: entityName,
  config: [
    {
      changements: ['created', 'updated', 'deleted', 'archived'],
      listener: () => {
        queryClient.invalidateQueries(keys.lists())
      }
    }
  ]
}).init()
