import { lazy } from 'react'
import { ShoppingBag } from 'react-feather'

import { Routes } from '@infrastructure/navigation/routes'
import verticalNavigation from '@infrastructure/navigation/vertical'

const basePath = '/service-packages'

Routes.push({
  path: basePath,
  component: lazy(() => import('../components/ServicePackagesPage')),
  meta: {
    authRoute: true
  }
})

verticalNavigation.financesConfig.children.push({
  id: 'servicePackage',
  title: 'Service Packages',
  icon: <ShoppingBag size={20} />,
  navLink: basePath
})
