import { useSelector } from 'react-redux'

import BBLoader from '@atoms/BBLoader'

import { selectIsInitialized } from './_slice'

const UserLoader = ({ children }) => {
  const isInitialized = useSelector(selectIsInitialized)

  return isInitialized ? children : <BBLoader />
}

export default UserLoader
