// ** React Imports
import { useEffect, useState } from 'react'
import {
  CheckSquare,
  CreditCard,
  HelpCircle,
  Mail,
  MessageSquare,
  Power,
  Settings,
  User
} from 'react-feather'
// ** Store & Actions
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
// import { handleLogout } from '@store/actions/auth'
// ** Third Party Components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

// ** Custom Components
import Avatar from '@components/avatar'
// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
// ** Utils
import { isUserLoggedIn } from '@utils'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">Admin</div>
        {/*<Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />*/}
      </DropdownToggle>
      <DropdownMenu right>
        {/*<DropdownItem tag={Link} to="#" onClick={(e) => e.preventDefault()}>*/}
        {/*  <User size={14} className="mr-75" />*/}
        {/*  <span className="align-middle">Profile</span>*/}
        {/*</DropdownItem>*/}
        {/*<DropdownItem tag={Link} to="#" onClick={(e) => e.preventDefault()}>*/}
        {/*  <Mail size={14} className="mr-75" />*/}
        {/*  <span className="align-middle">Inbox</span>*/}
        {/*</DropdownItem>*/}
        {/*<DropdownItem tag={Link} to="#" onClick={(e) => e.preventDefault()}>*/}
        {/*  <CheckSquare size={14} className="mr-75" />*/}
        {/*  <span className="align-middle">Tasks</span>*/}
        {/*</DropdownItem>*/}
        {/*<DropdownItem tag={Link} to="#" onClick={(e) => e.preventDefault()}>*/}
        {/*  <MessageSquare size={14} className="mr-75" />*/}
        {/*  <span className="align-middle">Chats</span>*/}
        {/*</DropdownItem>*/}
        <DropdownItem tag={Link} to="/logout">
          <Power size={14} className="mr-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
