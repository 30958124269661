import { userAgeUnitMap } from '@features/user/common/constants'
import { fromDefaultValues, joinNonEmptyStrings } from '@utils'

const defaultData = {
  id: undefined,
  sex: null,
  ageFrom: 0,
  ageFromUnit: userAgeUnitMap.year,
  ageTo: 150,
  ageToUnit: userAgeUnitMap.year,
  valueFrom: '',
  valueTo: ''
}

const transformToFormData = (data = {}) => fromDefaultValues(data, defaultData)

const ageUnitShortageMap = {
  year: 'y',
  week: 'w',
  month: 'm',
  day: 'd'
}

const createAgeMessage = ({ ageFrom, ageTo, ageFromUnit, ageToUnit }) => {
  const isSameUnit = ageFromUnit === ageToUnit
  return joinNonEmptyStrings(
    '-',
    ageFrom > 0
      ? isSameUnit
        ? ageFrom
        : `${ageFrom}${ageUnitShortageMap[ageFromUnit]}`
      : ageFrom,
    ageTo && `${ageTo}${ageUnitShortageMap[ageToUnit]}`
  )
}

export default {
  createAgeMessage,
  transformToFormData
}
