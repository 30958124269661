import { useEffect } from 'react'

import apiSocket from './apiSocket'

const useApiSocket = (connect) => {
  useEffect(() => {
    if (connect) {
      apiSocket.connect()
    }
    return () => {
      if (connect) {
        apiSocket.disconnect()
      }
    }
  }, [connect])
  return apiSocket
}

export default useApiSocket
