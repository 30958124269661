import { camelizeRespDataKeys, customSnakecaseKeys } from '@common/utils'
import restApi from '@infrastructure/api/restApi'

const markerNormApi = {
  create: (data) =>
    restApi.post('/admin/marker-norms', customSnakecaseKeys(data)),
  getList: () => restApi.get(`/admin/marker-norms`).then(camelizeRespDataKeys),
  getDetailById: (id) =>
    restApi.get(`/admin/marker-norms/${id}`).then(camelizeRespDataKeys),
  updateById: (id, data) =>
    restApi.put(`/admin/marker-norms/${id}`, customSnakecaseKeys(data)),
  deleteById: (id) => restApi.delete(`/admin/marker-norms/${id}`)
}

export default markerNormApi
