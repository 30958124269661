import { keyBy } from 'lodash-es'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'

import { articlesActions } from '@features/articlesSection/article/redux/articleSlice'
import { toastApiError } from '@src/utility/apiUtils'
import { mergeCbProps } from '@utils'

import markerCategoryRQKeys from '../reactQuery/markerCategoryRQKeys'
import markerCategoryApi from './markerCategoryApi'

export const useGetMarkerCategoryListQ = () => {
  const dispatch = useDispatch()
  return useQuery(markerCategoryRQKeys.list(), async () => {
    const {
      data: { markerCategories, articles }
    } = await markerCategoryApi.getList()
    const articleById = keyBy(articles)
    dispatch(articlesActions.addPreviews(articles))
    return markerCategories.map(({ articleId, ...rest }) => ({
      article: articleById[articleId],
      ...rest
    }))
  })
}

export const useGetMarkerCategoryPreviewListQ = (options) =>
  useQuery(
    markerCategoryRQKeys.previewList(),
    () => markerCategoryApi.getPreviewList().then((resp) => resp.data),
    options
  )

export const useCreateMarkerCategoryM = (options = undefined) => {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => markerCategoryApi.create(data),
    mergeCbProps(
      {
        onSuccess: () => {
          queryClient.invalidateQueries(markerCategoryRQKeys.lists())
        },
        onError: toastApiError
      },
      options
    )
  )
}

export const useUpdateMarkerCategoryM = (options = undefined) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...data }) => markerCategoryApi.updateById(id, data),
    mergeCbProps(
      {
        onSuccess: (data, { id }) => {
          queryClient.invalidateQueries(markerCategoryRQKeys.detail(id), {
            cancelRefetch: true
          })
          queryClient.invalidateQueries(markerCategoryRQKeys.lists())
        },
        onError: toastApiError
      },
      options
    )
  )
}

export const useDeleteMarkerCategoryM = (options = undefined) => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }) => markerCategoryApi.deleteById(id),
    mergeCbProps(
      {
        onSuccess: (data, { id }) => {
          queryClient.setQueriesData(markerCategoryRQKeys.lists(), (oldData) =>
            oldData.filter((item) => item.id !== id)
          )
          queryClient.removeQueries(markerCategoryRQKeys.detail(id))
        },
        onError: toastApiError
      },
      options
    )
  )
}
