import {
  ChevronLeft,
  ChevronRight,
  Divide,
  Minus,
  Plus,
  X
} from 'react-feather'

import { fromDefaultValues } from '@utils'

import expressionNormHelpers from './expressionNormHelpers'
import formulaVariableHelpers from './formulaVariableHelpers'

const operatorPattern = '[\\/*+-]'

export const operatorRegExp = new RegExp(operatorPattern, 'g')

export const componentByOperator = {
  '/': Divide,
  '*': X,
  '+': Plus,
  '-': Minus
}

const ElementOf = ({ className }) => <span className={className}>&isin;</span>

export const componentByCondition = {
  gt: ChevronRight,
  lt: ChevronLeft,
  in: ElementOf
}

const createExpressionRegExp = (variables = null) => {
  const variablesPattern = variables?.length
    ? variables.join('|')
    : formulaVariableHelpers.namePattern
  return new RegExp(
    `(${variablesPattern}){1}(\\s?${operatorPattern}{1}(?=(\\s?(${variablesPattern})){1}))?`
  )
}

const expressionConditionMap = {
  gt: 'gt',
  lt: 'lt',
  in: 'in'
}

const defaultFormData = {
  id: undefined,
  expression: '',
  condition: expressionConditionMap.in,
  norms: [],
  isTrackOnly: false
}

const transformToFormData = (data = {}) => {
  const { norms, ...rest } = fromDefaultValues(data, defaultFormData)
  return {
    ...rest,
    norms: norms.map((item) => expressionNormHelpers.transformToFormData(item))
  }
}
const conditionOptions = [
  {
    label: '< norm',
    value: expressionConditionMap.lt
  },
  {
    label: 'in norm',
    value: expressionConditionMap.in
  },
  {
    label: '> norm',
    value: expressionConditionMap.gt
  }
]

export default {
  expressionConditionMap,
  operatorRegExp,
  componentByOperator,
  conditionOptions,
  transformToFormData,
  createExpressionRegExp
}
