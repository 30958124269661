import axios from 'axios'

import { API_URL } from '../../common/constants'

const dateKeyPattern = /(^date$)|((_at|At|_date|Date)$)|(^birthday$)/

const restApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  transformResponse: (data) => {
    try {
      return JSON.parse(
        data,
        (key, value) =>
          (dateKeyPattern.test(key) && value && new Date(value)) || value
      )
    } catch (e) {
      if (typeof data === 'string') {
        return data
      }
      return undefined
    }
  }
})

export default restApi
