import { makePriceTitle } from '@features/accounting/repository'
import restApi from '@infrastructure/api/restApi'

export const name = 'servicePackage'

export const orderName = 'servicePackageOrder'

export const acquiringName = 'servicePackageOrderAcquiring'

export const userPackageName = 'userServicePackage'

export const api = {
  create: (data) => restApi.post('/admin/service-packages', data),
  getList: () => restApi.get(`/admin/service-packages`),
  getPreviewList: () => restApi.get(`/admin/service-packages/previews`),
  updateById: (id, data) => restApi.put(`/admin/service-packages/${id}`, data),
  deleteById: (id) => restApi.delete(`/admin/service-packages/${id}`),
  assign: (data) => restApi.post(`/admin/user-service-packages/assign`, data)
}

export const getAcquiringsHistory = () =>
  restApi.get('/admin/service-package-orders/acquirings/history')

export const makeVariationTitle = ({ quantity, amount, ccy }) =>
  `${quantity} stage(s) for ${makePriceTitle(amount, ccy)}`

const servicePackageRepository = { name, userPackageName }

export default servicePackageRepository
