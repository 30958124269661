import { toast as toastify } from 'react-toastify'

import {
  ErrorToast,
  InfoToast,
  PrimaryToast,
  SuccessToast,
  WarningToast
} from '@atoms/Toasts'

const defaultOptions = {
  autoClose: 3000,
  hideProgressBar: true,
  closeButton: false
}

const toast = (() => {
  const toasts = function (props, options = defaultOptions) {
    return toastify.success(<PrimaryToast {...props} />, options)
  }
  toasts.success = (props, options = defaultOptions) =>
    toastify.success(<SuccessToast {...props} />, options)
  toasts.warning = (props, options = defaultOptions) =>
    toastify.warning(<WarningToast {...props} />, options)
  toasts.error = (props, options = defaultOptions) =>
    toastify.error(<ErrorToast {...props} />, options)
  toasts.info = (props, options = defaultOptions) =>
    toastify.info(<InfoToast {...props} />, options)
  return toasts
})()

export default toast
