import markerGroupHelpers from '@features/analysesSection/markerGroups/service/markerGroupHelpers'
import markerPanelHelpers from '@features/analysesSection/markerPanel/service/markerPanelHelpers'
import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'

import applicationDataRQKeys from '../reactQuery/applicationDataRQKeys'

export default createEntityChangementConfig2({
  entity: 'application_data',
  dependenciesConfig: [
    {
      entities: [
        markerGroupHelpers.entityName,
        markerPanelHelpers.entityName,
        'marker',
        'marker_category'
      ],
      changements: ['created', 'updated', 'deleted'],
      listener: () => {
        queryClient.invalidateQueries(applicationDataRQKeys.previews())
      }
    }
  ]
})
