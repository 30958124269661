import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'
import toast from '@infrastructure/toast/toast'

import { keys } from '../reactQuery'
import userSurveyHelpers from '../service/userSurveyHelpers'

const listener = () => {
  toast.info({
    title: 'Success'
  })
}

export default createEntityChangementConfig2({
  entity: userSurveyHelpers.entityName,
  config: [
    {
      changements: ['created'],
      listener
    },
    {
      changements: ['passed'],
      listener
    },
    {
      changements: ['deleted'],
      listener
    },
    {
      changements: ['created', 'updated', 'passed', 'deleted'],
      listener: ({ meta: { userId } }) => {
        queryClient.invalidateQueries(keys.previews({ query: { userId } }))
      }
    }
  ]
})
