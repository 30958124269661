import { property } from 'lodash-es'
import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import { toastApiResponseMOptions } from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import { api, getAcquiringsHistory, name } from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  list: () => [...keys.lists(), 'list'],
  previewList: () => [...keys.lists(), 'preview'],
  acquiringsHistory: () => [...keys.all, 'acquirings', 'history'],
  details: () => [...keys.all, 'detail'],
  detail: (id) => [...keys.details(), id]
}

const getManyProp = property('data.servicePackages')

export const usePreviewListQ = (options) =>
  useQuery({
    ...options,
    queryKey: keys.previewList(),
    queryFn: () => api.getPreviewList().then(getManyProp)
  })

export const useListQ = (options) =>
  useQuery({
    queryKey: keys.list(),
    queryFn: () => api.getList().then(getDataProp),
    ...options
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => api.create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => api.updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => api.deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useAssignM = (options) =>
  useMutation({
    mutationFn: ({ data }) => api.assign(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

const getProp = property('data.servicePackageOrderAcquirings')

export const useAcquiringsHistoryQ = (options) =>
  useQuery({
    queryKey: keys.acquiringsHistory(),
    queryFn: () => getAcquiringsHistory().then(getProp),
    ...options
  })

const servicePackageRQ = { useAcquiringsHistoryQ, usePreviewListQ, useAssignM }

export default servicePackageRQ
