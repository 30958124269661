import { camelizeRespDataKeys } from '@common/utils'
import recommendationBasisRepository from '@features/recommendationBasis/repository'
import restApi from '@infrastructure/api/restApi'

export const name = 'recommendationPrototype'

export const todoName = 'recProtTodo'

export const intervalName = 'recProtTodoInterval'

export const create = (data) =>
  restApi.post('/admin/recommendation-prototypes', data)

export const getOverviews = ({
  page = undefined,
  pageLimit = undefined
} = {}) =>
  restApi
    .get('/admin/recommendation-prototypes', {
      params: {
        page,
        pageLimit
      }
    })
    .then(camelizeRespDataKeys)

export const getPreviews = ({
  search = undefined,
  page = undefined,
  pageLimit = undefined
} = {}) =>
  restApi
    .get('/admin/recommendation-prototypes/previews', {
      params: {
        search,
        page,
        pageLimit
      }
    })
    .then(camelizeRespDataKeys)

export const getDetailById = (id) =>
  restApi
    .get(`/admin/recommendation-prototypes/${id}`)
    .then(camelizeRespDataKeys)

export const updateById = (id, data) =>
  restApi.put(`/admin/recommendation-prototypes/${id}`, data)

export const deleteById = (id) =>
  restApi.delete(`/admin/recommendation-prototypes/${id}`)

export const createRecTodo = (id, data) =>
  restApi.post(`/admin/recommendation-prototypes/${id}/todos`, data)

export const updateTodoById = (id, data) =>
  restApi.put(`/admin/recommendation-prototypes/todos/${id}`, data)

export const deleteTodoById = (id) =>
  restApi.delete(`/admin/recommendation-prototypes/todos/${id}`)

export const createTodoInterval = (id, data) =>
  restApi.post(`/admin/recommendation-prototypes/todos/${id}/intervals`, data)

export const reorderTodoIntervals = (id, data) =>
  restApi.patch(
    `/admin/recommendation-prototypes/todos/${id}/intervals/reorder`,
    data
  )

export const updateIntervalById = (id, data) =>
  restApi.put(`/admin/recommendation-prototypes/todos/intervals/${id}`, data)

export const deleteIntervalById = (id, data) =>
  restApi.delete(`/admin/recommendation-prototypes/todos/intervals/${id}`, data)

export const defaultCValues = {
  ...recommendationBasisRepository.defaultCValues,
  note: ''
}

export const defaultEValues = {
  ...recommendationBasisRepository.defaultEValues,
  note: ''
}

export const makePreviewTitle = (data) =>
  data.title.uk.concat(data.note ? ` (нот.: ${data.note})` : '')
