import restApi from '@infrastructure/api/restApi'

export const name = 'userSurvey'

export const getOverviews = ({ query } = {}) =>
  restApi.get(`/admin/user-surveys`, { params: query })

export const getPreviews = ({ query } = {}) =>
  restApi.get(`/admin/user-surveys/previews`, { params: query })

export const getDetailById = (id) => restApi.get(`/admin/user-surveys/${id}`)

export const getSurveyResultsById = (id) =>
  restApi.get(`/admin/user-surveys/${id}/results`)

export const create = (data) => restApi.post('/admin/user-surveys', data)
