import { useCallback } from 'react'

import { byId } from '@common/utils'
import supplementRQ from '@features/supplements/reactQuery'
import todoTaskRQ from '@features/todoTask/reactQuery'

import { typeMap } from '../repository'

export function useMakeShortTitle() {
  const { isLoading: tasksLoading, data: taskById } = todoTaskRQ.usePreviewsQ({
    select: byId
  })
  const { isLoading: dosesLoading, data: doseById } = supplementRQ.useDosesQ({
    select: byId
  })
  const makeDosePreviewTitle = supplementRQ.useMakeSuppWithDosePreviewTitle()
  return useCallback(
    (data) =>
      tasksLoading || dosesLoading
        ? '...'
        : data.type === typeMap.task
        ? `Завдання: ${taskById[data.asTask.taskId].title}`
        : `Харч. добавка: ${makeDosePreviewTitle(
            doseById[data.asSupplement.supplementDoseId]
          )}`,
    [tasksLoading, dosesLoading, taskById, doseById, makeDosePreviewTitle]
  )
}

export function useMakePreviewTitle() {
  const makeShortTitle = useMakeShortTitle()
  return useCallback(
    (data) =>
      makeShortTitle(data).concat(
        ` (за день: ${data.whens.length}, періодів: ${data.intervals.length})`
      ),
    [makeShortTitle]
  )
}

const todoBasisRQ = { useMakeShortTitle, useMakePreviewTitle }

export default todoBasisRQ
