import todoBasisRepository from '@features/todoBasis/repository'
import restApi from '@infrastructure/api/restApi'

export const name = 'todoPrototype'

export const intervalName = 'todoProtInterval'

export const create = (data) =>
  restApi.post(`/admin/todo-prototypes`, data, { deep: true })

export const getOverviews = () => restApi.get(`/admin/todo-prototypes`)

export const getPreviews = () => restApi.get(`/admin/todo-prototypes/previews`)

export const getDetailById = (id) => restApi.get(`/admin/todo-prototypes/${id}`)

export const updateById = (id, data) =>
  restApi.put(`/admin/todo-prototypes/${id}`, data)

export const deleteById = (id) => restApi.delete(`/admin/todo-prototypes/${id}`)

export const createTodoInterval = (id, data) =>
  restApi.post(`/admin/todo-prototypes/${id}/intervals`, data)

export const reorderTodoIntervals = (id, data) =>
  restApi.patch(`/admin/todo-prototypes/${id}/intervals/reorder`, data)

export const updateIntervalById = (id, data) =>
  restApi.put(`/admin/todo-prototypes/intervals/${id}`, data)

export const deleteIntervalById = (id, data) =>
  restApi.delete(`/admin/todo-prototypes/intervals/${id}`, data)

export const defaultCValues = {
  ...todoBasisRepository.defaultCValues,
  note: ''
}

export const defaultEValues = {
  ...todoBasisRepository.defaultEValues,
  note: ''
}
