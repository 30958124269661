import { format } from 'date-fns'
import { serialize } from 'object-to-formdata'

import { DATE_FORMATS } from '@common/constants'
import recoveryStageBasisRepository from '@features/recoveryStageBasis/repository'
import restApi from '@infrastructure/api/restApi'

export const name = 'medicalConclusion'

export const stageName = 'medConcStage'

export const groupName = 'medConcStgGroup'

export const recommendationName = 'medConcStgGrRecommendation'

export const todoName = 'medConcStgGrRecTodo'

export const intervalName = 'medConcStgGrRecTodoInterval'

export const commonStatusMap = {
  terminated: 'terminated',
  active: 'active',
  pending: 'pending'
}

export const stageStatusMap = {
  finished: 'finished',
  active: 'active',
  pending: 'pending'
}

export const groupStatusMap = {
  unRevealed: 'unRevealed',
  ...commonStatusMap
}

export const recommendationStatusMap = groupStatusMap

export const todoStatusMap = {
  started: 'started',
  finished: 'finished',
  unStarted: 'unStarted',
  ...commonStatusMap
}

export const intervalStatusMap = {
  finished: 'finished',
  paused: 'finished',
  unStarted: 'unStarted',
  ...commonStatusMap
}

export const isStageTerminated = (status) => status === stageStatusMap.finished

export const isGroupTerminated = (status) =>
  status === groupStatusMap.unRevealed || status === groupStatusMap.terminated

export const isRecommendationTerminated = isGroupTerminated

export const isTodoTerminated = (status) =>
  status === todoStatusMap.unStarted ||
  status === todoStatusMap.finished ||
  status === todoStatusMap.terminated

export const isIntervalTerminated = isTodoTerminated

export const makeBasedOnSurveyTitle = ({ survey: { title }, passedAt }) =>
  `${title} від ${format(passedAt, DATE_FORMATS.DATE)}`

export const makeBasedOnAnalysesTitle = ({
  analyses: {
    category: { title }
  },
  takenDate
}) => `${title} від ${format(takenDate, DATE_FORMATS.DATE)}`

export const getOverviews = ({ query } = {}) =>
  restApi.get('/admin/medical-conclusions', {
    params: query
  })

export const getCurrentIdForUser = (userId) =>
  restApi.get(`/admin/medical-conclusions/users/${userId}/current/id`)

export const getPreviews = () =>
  restApi.get('/admin/medical-conclusions/previews')

export const getDetailById = (id) =>
  restApi.get(`/admin/medical-conclusions/${id}`)

export const finishCurrentStep = (id) =>
  restApi.post(`/admin/medical-conclusions/${id}/finish-current-step`)

export const startNextStep = (id) =>
  restApi.post(`/admin/medical-conclusions/${id}/start-next-step`)

export const updateDiagnosisById = (id, data) =>
  restApi.put(`/admin/medical-conclusions/${id}/diagnosis`, data)

export const deleteById = (id) =>
  restApi.delete(`/admin/medical-conclusions/${id}`)

export const createConcStage = (id, data) =>
  restApi.post(
    `/admin/medical-conclusions/${id}/stages`,
    recoveryStageBasisRepository.serializeCData(data)
  )

export const updateStageById = (id, data) =>
  restApi.put(`/admin/medical-conclusions/stages/${id}`, data)

export const deleteStageById = (id) =>
  restApi.delete(`/admin/medical-conclusions/stages/${id}`)

export const reorderConcStages = (id, data) =>
  restApi.patch(`/admin/medical-conclusions/${id}/stages/reorder`, data)

export const createStgGroup = (id, data) =>
  restApi.post(
    `/admin/medical-conclusions/stages/${id}/groups`,
    serialize(data, { noFilesWithArrayNotation: false, indices: true })
  )

export const updateGroupById = (id, data) =>
  restApi.put(
    `/admin/medical-conclusions/stages/groups/${id}`,
    serialize(data, { noFilesWithArrayNotation: false, indices: true })
  )

export const deleteGroupById = (id) =>
  restApi.delete(`/admin/medical-conclusions/stages/groups/${id}`)

export const archiveGroupById = (id) =>
  restApi.patch(`/admin/medical-conclusions/stages/groups/${id}/archive`)

export const reorderStgGroups = (id, data) =>
  restApi.patch(`/admin/medical-conclusions/stages/${id}/groups/reorder`, data)

export const createGrRecommendation = (id, data) =>
  restApi.post(
    `/admin/medical-conclusions/stages/groups/${id}/recommendations`,
    data
  )

export const updateRecommendationById = (id, data) =>
  restApi.put(
    `/admin/medical-conclusions/stages/groups/recommendations/${id}`,
    data
  )

export const deleteRecommendationById = (id) =>
  restApi.delete(
    `/admin/medical-conclusions/stages/groups/recommendations/${id}`
  )

export const archiveRecommendationById = (id) =>
  restApi.patch(
    `/admin/medical-conclusions/stages/groups/recommendations/${id}/archive`
  )

export const reorderGrRecommendations = (id, data) =>
  restApi.patch(
    `/admin/medical-conclusions/stages/groups/${id}/recommendations/reorder`,
    data
  )

export const createRecTodo = (id, data) =>
  restApi.post(
    `/admin/medical-conclusions/stages/groups/recommendations/${id}/todos`,
    data
  )

export const updateTodoById = (id, data) =>
  restApi.put(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/${id}`,
    data
  )

export const deleteTodoById = (id) =>
  restApi.delete(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/${id}`
  )

export const archiveTodoById = (id) =>
  restApi.patch(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/${id}/archive`
  )

export const createTodoInterval = (id, data) =>
  restApi.post(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/${id}/intervals`,
    data
  )

export const reorderTodoIntervals = (id, data) =>
  restApi.patch(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/${id}/intervals/reorder`,
    data
  )

export const updateIntervalById = (id, data) =>
  restApi.put(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/intervals/${id}`,
    data
  )

export const deleteIntervalById = (id, data) =>
  restApi.delete(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/intervals/${id}`,
    data
  )

export const archiveIntervalById = (id, data) =>
  restApi.patch(
    `/admin/medical-conclusions/stages/groups/recommendations/todos/intervals/${id}/archive`,
    data
  )

export const defaultCValues = {
  ...recoveryStageBasisRepository.defaultCValues,
  note: ''
}

export const defaultEValues = {
  ...recoveryStageBasisRepository.defaultEValues,
  note: ''
}

const medicalConclusionRepository = {
  name,
  stageName,
  groupName,
  recommendationName,
  todoName,
  intervalName
}

export default medicalConclusionRepository
