import { useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'

import { byId, getDataProp } from '@common/utils'
import doseUnitRQ from '@features/doseUnits/reactQuery'
import { toastApiResponseMOptions } from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  create,
  deleteById,
  getDetailById,
  getDoses,
  getOverviews,
  getPreviews,
  name,
  updateById
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  doses: () => [...keys.lists(), 'doses'],
  overviews: () => [...keys.lists(), 'overviews'],
  previews: () => [...keys.lists(), 'previews'],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id]
}

export const useOverviewsQ = (options) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    ...options
  })

export const usePreviewsQ = (options) =>
  useQuery({
    queryKey: keys.previews(),
    queryFn: () => getPreviews().then(getDataProp),
    ...options
  })

export const useDosesQ = (options) =>
  useQuery({
    queryKey: keys.doses(),
    queryFn: () => getDoses().then(getDataProp),
    ...options
  })

export const useDetailById = (id, options) =>
  useQuery(
    keys.detail(id),
    () => getDetailById(id).then(getDataProp),
    ...options
  )

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useMakeDosePreviewTitle = () => {
  const { data: doseUnitById, isLoading: unitsLoading } =
    doseUnitRQ.usePreviewsQ({
      select: byId
    })
  return useCallback(
    ({ value, unitId }) =>
      `${value} ${unitsLoading ? '...' : doseUnitById[unitId]?.title}`,
    [unitsLoading, doseUnitById]
  )
}

export const useMakeSuppWithDosePreviewTitle = () => {
  const { data: doseUnitById, isLoading: unitsLoading } =
    doseUnitRQ.usePreviewsQ({
      select: byId
    })
  const { data: supplementById, isLoading: supplementsLoading } = usePreviewsQ({
    select: byId
  })
  return useCallback(
    ({ supplementId, value, unitId }) =>
      `${
        supplementsLoading ? '...' : supplementById[supplementId]?.title
      } ${value} ${unitsLoading ? '...' : doseUnitById[unitId]?.title}`,
    [supplementsLoading, unitsLoading, supplementById, doseUnitById]
  )
}

const supplementRQ = {
  useDosesQ,
  useMakeSuppWithDosePreviewTitle
}

export default supplementRQ
