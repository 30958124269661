import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import {
  toastApiResponseMOptions,
  toastApiResponseQOptions
} from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  create,
  deleteById,
  getOverviews,
  name,
  updateById
} from '../repository'

export const keys = {
  all: [name],
  overviews: () => [...keys.all, 'overviews']
}

export const useOverviewsQ = (options = undefined) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    ...options,
    ...toastApiResponseQOptions
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })
