const markerUnitRQKeys = {
  all: ['markerUnit'],
  lists: () => [...markerUnitRQKeys.all, 'lists'],
  list: () => [...markerUnitRQKeys.lists(), 'full'],
  previewList: () => [...markerUnitRQKeys.lists(), 'preview'],
  details: () => [...markerUnitRQKeys.all, 'details'],
  detail: (id) => [...markerUnitRQKeys.list(), id]
}

export default markerUnitRQKeys
