const articleRQKeys = {
  all: ['article'],
  lists: () => [...articleRQKeys.all, 'lists'],
  parametrizedList: (params) => [
    ...articleRQKeys.lists(),
    'parametrizedList',
    params
  ],
  previewList: () => [...articleRQKeys.lists(), 'previewList'],
  filteredPreviewList: (query) => [...articleRQKeys.previewList(), { query }],
  details: () => [...articleRQKeys.all, 'detail'],
  detail: (id) => [...articleRQKeys.details(), id]
}

export default articleRQKeys
