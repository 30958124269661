import { property } from 'lodash-es'

import restApi from '@infrastructure/api/restApi'

export const create = (data) =>
  restApi.post('/admin/recommendation-group-assignment-automations', data)

export const getOverviews = () =>
  restApi.get(`/admin/recommendation-group-assignment-automations`)

export const updateById = (id, data) =>
  restApi.put(`/admin/recommendation-group-assignment-automations/${id}`, data)

export const deleteById = (id) =>
  restApi.delete(`/admin/recommendation-group-assignment-automations/${id}`)

export const archiveById = (id) =>
  restApi.patch(
    `/admin/recommendation-group-assignment-automations/${id}/archive`
  )

export const createAutPrototype = (id, data) =>
  restApi.post(
    `/admin/recommendation-group-assignment-automations/${id}/prototypes`,
    data
  )

export const deletePrototypeById = (id) =>
  restApi.delete(
    `/admin/recommendation-group-assignment-automations/prototypes/${id}`
  )

export const reorderAutPrototypes = (id, data) =>
  restApi.patch(
    `/admin/recommendation-group-assignment-automations/${id}/prototypes/reorder`,
    data
  )

export const archivePrototypeById = (id) =>
  restApi.patch(
    `/admin/recommendation-group-assignment-automations/prototypes/${id}/archive`
  )

export const name = 'recommendationGroupAssignmentAutomation'

export const prototypeName = 'recGrAssAutPrototype'

export const getPrototypesGroupId = property('groupId')
