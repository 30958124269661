export const invalidateQueryKeys = (keys, queryClient) => {
  if (Array.isArray(keys)) {
    for (const key of keys) {
      return queryClient.invalidateQueries(key)
    }
  }
}

export const removeQueryKeys = (keys, queryClient) => {
  if (Array.isArray(keys)) {
    for (const key of keys) {
      return queryClient.removeQueries(key)
    }
  }
}
