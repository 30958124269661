import { keyBy } from 'lodash-es'

import { intervalTypeMap } from '@infrastructure/interval/service/intervalHelpers'

export const resultNormRangeMap = {
  opt: 'opt',
  norm: 'norm',
  notInNorm: 'not_in_norm',
  gtNorm: 'gt_norm',
  ltNorm: 'lt_norm'
}

export const resultNormRangeToIntervalType = {
  [resultNormRangeMap.opt]: intervalTypeMap.exact,
  [resultNormRangeMap.norm]: intervalTypeMap.near,
  [resultNormRangeMap.notInNorm]: intervalTypeMap.far,
  [resultNormRangeMap.gtNorm]: intervalTypeMap.far,
  [resultNormRangeMap.ltNorm]: intervalTypeMap.far
}

export const transformToOverviewData = (
  {
    filledMarkerResultsData,
    unfilledMarkerResultsData,
    formulasEvaluationData,
    ...rest
  },
  { panels, markers, units, markerNorms, formulas }
) => {
  const panelById = Array.isArray(panels) ? keyBy(panels, 'id') : panels
  const markerById = Array.isArray(markers) ? keyBy(markers, 'id') : markers
  const unitById = Array.isArray(units) ? keyBy(units, 'id') : units
  const normById = Array.isArray(markerNorms)
    ? keyBy(markerNorms, 'id')
    : markerNorms
  const formulaById = Array.isArray(formulas)
    ? keyBy(
        formulas.map(({ variables, ...rest }) => ({
          ...rest,
          variables: variables.map(({ markerId, unitId, ...rest }) => ({
            ...rest,
            marker: markerById[markerId],
            unit: unitById[unitId]
          }))
        })),
        'id'
      )
    : formulas
  return {
    filledMarkerResultsData: filledMarkerResultsData.map(
      ({ panelId, markerResults, ...rest }) => ({
        ...rest,
        panel: panelById[panelId],
        markerResults: markerResults.map(
          ({
            markerId,
            unitId,
            meta: { normId, optimumId, ...restMeta },
            ...rest
          }) => ({
            ...rest,
            marker: markerById[markerId],
            unit: unitById[unitId],
            meta: {
              ...restMeta,
              norm: normId && normById[normId],
              optimum: optimumId && normById[optimumId]
            }
          })
        )
      })
    ),
    unfilledMarkerResultsData: unfilledMarkerResultsData.map(
      ({ panelId, markerResults, ...rest }) => ({
        ...rest,
        panel: panelById[panelId],
        markerResults: markerResults.map(({ markerId, ...rest }) => ({
          ...rest,
          marker: markerById[markerId]
        }))
      })
    ),
    formulasData: formulasEvaluationData.map(({ id, expressions, ...meta }) => {
      const { expressions: formulaExpressions, ...formula } = formulaById[id]
      return {
        id,
        ...formula,
        expressions: expressions.map(({ id, ...calculationMeta }) => {
          const { meta, ...expression } = formulaExpressions.find(
            (item) => item.id === id
          )
          return {
            ...expression,
            meta: {
              ...meta,
              ...calculationMeta
            }
          }
        }),
        meta
      }
    }),
    ...rest
  }
}

export const transformToOverviewDataArr = (
  arr,
  { panels, markers, units, markerNorms, formulas }
) => {
  const panelById = keyBy(panels, 'id')
  const markerById = keyBy(markers, 'id')
  const unitById = keyBy(units, 'id')
  const normById = keyBy(markerNorms, 'id')
  const formulaById = Array.isArray(formulas)
    ? keyBy(
        formulas.map(({ variables, ...rest }) => ({
          ...rest,
          variables: variables.map(({ markerId, unitId, ...rest }) => ({
            ...rest,
            marker: markerById[markerId],
            unit: unitById[unitId]
          }))
        })),
        'id'
      )
    : formulas
  return arr.map((item) =>
    transformToOverviewData(item, {
      panels: panelById,
      markers: markerById,
      units: unitById,
      markerNorms: normById,
      formulas: formulaById
    })
  )
}

export default {
  entityName: 'user_analyses',
  transformToOverviewData,
  transformToOverviewDataArr
}
