import { Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// ** Service Worker
import { QueryClientProvider } from 'react-query'
// ** Redux Imports
import { Provider } from 'react-redux'
// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'

import '@features/accounting'
import UserLoader from '@features/auth/UserLoader'
import '@features/medicalConclusion'
import '@features/promocode'
import '@features/recGroupAssignmentAutomation'
import '@features/recGroupPrototype'
import '@features/recommendationPrototype'
import '@features/recoveryStagePrototype'
import '@features/servicePackage'
import '@features/supplements'
import '@features/surveyAssignmentAutomation'
import '@features/todoPrototype'
// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
// ** Ripple Button
import './@core/components/ripple-button'
// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
// import './@core/scss/core.scss'
import './assets/scss/style.scss'
import Router from './infrastructure/navigation/Router'
import queryClient from './infrastructure/reactQuery/queryClient'
import { store } from './infrastructure/reduxStore/store'
import { ThemeContext } from './utility/context/ThemeColors'

//moment.locale('ru', ruLocale)

const App = () => (
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <ThemeContext>
        <QueryClientProvider client={queryClient}>
          <UserLoader>
            <DndProvider backend={HTML5Backend}>
              <Router />
            </DndProvider>
            <ToastContainer newestOnTop />
          </UserLoader>
        </QueryClientProvider>
      </ThemeContext>
    </Provider>
  </Suspense>
)

export default App
