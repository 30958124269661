import { isNil } from 'lodash-es'

export const articleTypeMap = Object.freeze({
  recommendation: 'recommendation',
  supplement: 'supplement',
  conclusion: 'conclusion',
  marker: 'marker',
  info: 'info',
  recommendationsStage: 'recommendations_stage'
})

export const articleTypes = Object.values(articleTypeMap)

export default {
  entityName: 'article'
}

export const articleTypeFilterFn = (rows, id, filterValue) => {
  if (!filterValue?.length) {
    return rows
  }
  return rows.filter((row) => {
    const value = row.values[id]
    if (!value) {
      return false
    }
    for (const { type } of filterValue) {
      if (value === type) {
        return true
      }
    }
    return false
  })
}

articleTypeFilterFn.autoRemove = isNil

export const articleCategoryFilterFn = (rows, id, filterValue) => {
  if (!filterValue?.length) {
    return rows
  }
  return rows.filter((row) => {
    const value = row.values[id]
    if (!value) {
      return false
    }
    for (const { id: fId } of filterValue) {
      if (value.id === fId) {
        return true
      }
    }
    return false
  })
}

articleCategoryFilterFn.autoRemove = isNil
