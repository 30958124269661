import { lazy } from 'react'

import { Routes } from '@infrastructure/navigation/routes'

export const detailPath = '/medical-conclusions/:id'

export const makeDetailPath = (id) => `/medical-conclusions/${id}`

Routes.push({
  path: detailPath,
  component: lazy(() => import('../components/ConclusionPage')),
  meta: {
    authRoute: true
  }
})

const medicalConclusionNavigation = { detailPath, makeDetailPath }

export default medicalConclusionNavigation
