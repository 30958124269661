/* eslint-disable no-unused-vars */
import { keyBy } from 'lodash-es'
import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { byId, getDataProp } from '@common/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'

import surveyApi from '../service/surveyApi'
import { getSurveyLabel } from '../service/surveyHelpers'
import surveyRQKeys from './surveyRQKeys'

export const useSurveyListQ = (options) =>
  useQuery(surveyRQKeys.list(), () => surveyApi.getList().then(getDataProp))

export const useSurveyByIdQ = (id, options = undefined) =>
  useQuery(
    surveyRQKeys.detail(id),
    () => surveyApi.getDetailById(id).then(getDataProp),
    {
      ...options,
      retry: false
    }
  )

export const useSurveyPreviewsQ = (options) =>
  useQuery({
    queryKey: surveyRQKeys.previews(),
    queryFn: () => surveyApi.getPreviews().then(getDataProp),
    ...options
  })

export const useCreateSurvey = () => {
  const queryClient = useQueryClient()
  return useMutation(({ data }) => surveyApi.create(data).then(getDataProp), {
    onSuccess: () => {
      queryClient.invalidateQueries(surveyRQKeys.list())
    }
  })
}

export const useUpdateSurvey = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id, data }) => surveyApi.updateById(id, data), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(surveyRQKeys.list())
      queryClient.invalidateQueries(surveyRQKeys.detail(id), {
        cancelRefetch: true
      })
    }
  })
}

export const useDeleteSurvey = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => surveyApi.deleteById(id), {
    onSuccess: (data, { id }) => {
      queryClient.setQueriesData(surveyRQKeys.list(), (oldData) =>
        oldData.filter((data) => data.id !== id)
      )
      queryClient.removeQueries(surveyRQKeys.detail(id))
    }
  })
}

export const useCreateQuestionM = (options = null) =>
  useMutation(surveyApi.createQuestion, options)

export const useUpdateQuestionM = (options = null) =>
  useMutation(surveyApi.updateQuestion, options)

export const useDeleteQuestionM = (options = null) =>
  useMutation(surveyApi.deleteQuestion, options)

export const useSetQuestionOptionsOrder = (options = null) =>
  useMutation(surveyApi.setQuestionOptionsOrder, options)

const previewsConfig = {
  queryKey: surveyRQKeys.previews(),
  queryFn: () => surveyApi.getPreviews().then(getDataProp)
}

export const usePreviewsQ = (options = undefined) =>
  useQuery({
    ...previewsConfig,
    ...options
  })

export const usePreviewByIdMapQ = (options = undefined) =>
  usePreviewsQ({
    ...options,
    select: (data) => keyBy(data, 'id')
  })

export const useCloneByIdM = (options = undefined) =>
  useMutation(({ id }) => surveyApi.cloneById(id).then(getDataProp), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(surveyRQKeys.list())
    }
  })

export const useSetQuestionsOrderM = (options = undefined) =>
  useMutation(surveyApi.setQuestionsOrder, options)

export const useCreateQuestionOptionM = (options = null) =>
  useMutation(surveyApi.createQuestionOption, options)

export const useUpdateQuestionOptionM = (options = null) =>
  useMutation(surveyApi.updateQuestionOption, options)

export const useDeleteQuestionOptionM = (options = null) =>
  useMutation(surveyApi.deleteQuestionOption, options)

export const useCreateQuestionBioConditionM = (options = null) =>
  useMutation(surveyApi.createQuestionBioCondition, options)

export const useDeleteQuestionBioConditionM = (options = null) =>
  useMutation(surveyApi.deleteQuestionBioCondition, options)

export const useCreateQuestionQuestionAnswerConditionM = (options = null) =>
  useMutation(surveyApi.createQuestionQuestionAnswerCondition, options)

export const useDeleteQuestionQuestionAnswerConditionM = (options = null) =>
  useMutation(surveyApi.deleteQuestionQuestionAnswerCondition, options)

export function useMakePreviewTitleById() {
  const { data: previewById, isLoading } = useSurveyPreviewsQ({
    select: byId
  })
  return useCallback(
    (id) => (isLoading ? '...' : getSurveyLabel(previewById[id]) ?? '?'),
    [isLoading, previewById]
  )
}

export default {
  usePreviewsQ,
  usePreviewByIdMapQ
}
