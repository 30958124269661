import { groupBy } from 'lodash-es'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import todoBasisRQ from '@features/todoBasis/reactQuery'
import todoBasisRepository from '@features/todoBasis/repository'
import {
  toastApiResponseMOptions,
  toastApiResponseQOptions
} from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  create,
  createTodoInterval,
  deleteById,
  deleteIntervalById,
  getDetailById,
  getOverviews,
  getPreviews,
  name,
  reorderTodoIntervals,
  updateById,
  updateIntervalById
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  overviews: () => [...keys.lists(), 'overviews'],
  previews: () => [...keys.lists(), 'previews'],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id]
}

const selectArrDataTree = (data) => {
  const { todoPrototypes, intervals } = data
  const intervalsByTodoId = groupBy(intervals, 'todoId')
  return todoPrototypes.map((todo) =>
    todoBasisRepository.createDataTree({ todo, intervalsByTodoId })
  )
}

const selectDataTree = (data) => {
  const { todoPrototype, intervals } = data
  const intervalsByTodoId = groupBy(intervals, 'todoId')
  return todoBasisRepository.createDataTree({
    todo: todoPrototype,
    intervalsByTodoId
  })
}

export const usePreviewsQ = (options) =>
  useQuery({
    queryKey: keys.previews(),
    queryFn: () => getPreviews().then(getDataProp),
    select: selectArrDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useOverviewsQ = (options) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    select: selectArrDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useDetailByIdQ = (id, options) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDetailById(id).then(getDataProp),
    select: selectDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateTodoIntervalM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createTodoInterval(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateIntervalById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteIntervalById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderTodoIntervalsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderTodoIntervals(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useMakePreviewTitle = () => {
  const makePreviewTitle = todoBasisRQ.useMakeShortTitle()
  return useCallback(
    (data) => `${makePreviewTitle(data)}${data.note ? ` (${data.note})` : ''} `,
    [makePreviewTitle]
  )
}
