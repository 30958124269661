import { compact } from 'lodash-es'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import recGroupPrototypeRQ from '@features/recGroupPrototype/reactQuery'
import {
  toastApiResponseMOptions,
  toastApiResponseQOptions
} from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  archiveById,
  archivePrototypeById,
  create,
  createAutPrototype,
  deleteById,
  deletePrototypeById,
  getOverviews,
  getPrototypesGroupId,
  name,
  reorderAutPrototypes,
  updateById
} from '../repository'

export const keys = {
  all: [name],
  overviews: () => [...keys.all, 'overviews']
}

export const useOverviewsQ = (options) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    ...options,
    ...toastApiResponseQOptions
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useArchiveByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => archiveById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateAutPrototypeM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createAutPrototype(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useArchivePrototypeByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => archivePrototypeById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeletePrototypeByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deletePrototypeById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderAutPrototypesM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderAutPrototypes(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useMakePrototypeTitle = ({ enableOrder = true } = {}) => {
  const makeTitle =
    recGroupPrototypeRQ.useMakePreviewTitleById(getPrototypesGroupId)
  return useCallback(
    (data) =>
      compact([enableOrder && `${data.order + 1}.`, makeTitle(data)]).join(' '),
    [makeTitle]
  )
}
