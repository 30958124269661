/* eslint-disable no-use-before-define */

/* eslint-disable no-mixed-operators */
import { EventEmitter } from '@src/../node_modules/events'

import restApi from '../../infrastructure/api/restApi'

export const AUTH_EVENTS = {
  login: 'login',
  logout: 'logout',
  error: 'error',
  initialized: 'initialized'
}

function CreateAuthService() {
  const tokenExp = 14 * 60 * 1000
  const events = new EventEmitter()
  let isInitialized = false
  let isAuthFailed = false
  let isLoggedIn = false
  let isLoggingIn = false
  let authData = null
  let authError = null
  let tokenTimer = null
  let tokenExpTimestamp = null

  function getIsInitialized() {
    return isInitialized
  }

  function setIsInitialized(value) {
    isInitialized = value
  }

  function getIsLoggedIn() {
    return isLoggedIn
  }

  function setIsLoggedIn(value) {
    isLoggedIn = value
  }

  function getIsLoggingIn() {
    return isLoggingIn
  }

  function setIsLoggingIn(value) {
    isLoggingIn = value
  }

  function getAuthData() {
    return authData
  }

  function setAuthData(value) {
    authData = value
  }

  function getAuthError() {
    return authError
  }

  function setAuthError(value) {
    authError = value
  }

  function getIsAuthFailed() {
    return isAuthFailed
  }

  function setIsAuthFailed(value) {
    isAuthFailed = value
  }

  function getTokenExpTimestamp() {
    return tokenExpTimestamp
  }

  function setTokenExpTimestamp(value) {
    tokenExpTimestamp = value
  }

  function clearTokenTimer() {
    if (tokenTimer) {
      clearTimeout(tokenTimer)
      tokenTimer = null
    }
    setTokenExpTimestamp(0)
  }

  function handleAuthSuccess(response) {
    setAuthData(response.data)
    setIsLoggingIn(false)
    setIsLoggedIn(true)
    setIsAuthFailed(false)
    setAuthError(null)
    clearTokenTimer()

    setTokenExpTimestamp(Date.now() + tokenExp)
    tokenTimer = setTimeout(refreshToken, tokenExp)

    events.emit(AUTH_EVENTS.login, response.data)
  }

  function handleAuthError(error) {
    setAuthData(null)
    setIsLoggingIn(false)
    setIsLoggedIn(false)
    setIsAuthFailed(true)
    setAuthError(error)
    clearTokenTimer()
    events.emit(AUTH_EVENTS.error, error)
  }

  async function login(email, password) {
    if (getIsLoggingIn()) {
      return
    }

    setIsLoggingIn(true)

    try {
      const resp = await restApi.post('/admin/auth/login', {
        email,
        password
      })
      handleAuthSuccess(resp)
      return resp
    } catch (e) {
      handleAuthError(e.response)
      return Promise.reject(e)
    }
  }

  async function logout() {
    try {
      return restApi.post('/admin/auth/logout')
    } finally {
      setAuthData(null)
      events.emit(AUTH_EVENTS.logout)
    }
  }

  async function refreshToken() {
    if (getIsLoggingIn() || getIsAuthFailed()) {
      return
    }

    setIsLoggingIn(true)

    try {
      const resp = await restApi.post('/admin/auth/refresh-token')
      handleAuthSuccess(resp)
      return resp
    } catch (e) {
      handleAuthError(e.response)
      return Promise.reject(e)
    }
  }

  async function initialize() {
    if (getIsInitialized()) {
      return
    }

    window?.addEventListener('focus', async () => {
      if (getTokenExpTimestamp() < Date.now()) {
        await refreshToken()
      }
    })

    restApi.interceptors.response.use(
      (response) => Promise.resolve(response),
      async (error) => {
        if (
          error.response?.status === 401 &&
          (console.log(getTokenExpTimestamp(), Date.now()) ||
            getTokenExpTimestamp() < Date.now())
        ) {
          await refreshToken().catch((e) => {})
        } else if (error.response?.status === 403) {
          handleAuthError(error.response)
        }
        return Promise.reject(error)
      }
    )

    try {
      await refreshToken().catch((e) => {})
    } finally {
      setIsInitialized(true)
      events.emit(AUTH_EVENTS.initialized)
    }
  }

  return {
    addListener: events.addListener.bind(events),
    removeListener: events.removeListener.bind(events),
    listenerCount: events.listenerCount.bind(events),
    getAuthData,
    getIsLoggedIn,
    getIsLoggingIn,
    getIsInitialized,
    initialize,
    login,
    logout,
    refreshToken
  }
}

const authService = CreateAuthService()

export default authService
