import { entityChangementEE } from './constants'
import entityChangementConfig from './entitiesChangementConfig'
import { getEntityChangementEventName } from './utils'

entityChangementConfig.init()

export default (data) => {
  const { name, type, meta } = data
  entityChangementEE.emit(
    getEntityChangementEventName(name, type),
    {
      ...data,
      meta: meta ?? {}
    },
    {}
  )
}
