// ** Service Worker
import { StrictMode } from 'react'
import { render } from 'react-dom'

import { NODE_ENV, SENTRY_ENV } from '@common/env'
import * as Sentry from '@sentry/react'

import App from './App'
import * as serviceWorker from './serviceWorker'

if (NODE_ENV !== 'development') {
  Sentry.init({
    environment: SENTRY_ENV ?? NODE_ENV,
    dsn: 'https://1ea4ced54d3c44a3a5548b44eb15d786@o4505029536317440.ingest.sentry.io/4505040391700480',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
