import { lazy } from 'react'

import { NODE_ENV } from '@common/env'

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/dashboard'

// ** Merge Routes
const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('@features/statistics/DashboardPage')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/users/:id/overview',
    component: lazy(() => import('@features/user/components/UserOverviewPage')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/users/:id',
    component: lazy(() => import('@features/user/components/UserDetailPage')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/users',
    component: lazy(() => import('@features/user/components/UsersPage')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/supplements',
    component: lazy(
      () => import('@features/supplements/components/SupplementsPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/supplement-dose-units',
    component: lazy(
      () => import('@features/doseUnits/components/DoseUnitsPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/todo-whens',
    component: lazy(
      () => import('@features/todoWhens/components/TodoWhensPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/todo-tasks',
    component: lazy(
      () => import('@features/todoTask/components/TodoTasksPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/markers/:markerId',
    component: lazy(
      () =>
        import('@features/analysesSection/markers/components/MarkerDetailPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/markers',
    component: lazy(
      () => import('@features/analysesSection/markers/components/MarkersPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/marker-groups/:groupId',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/markerGroups/components/MarkerGroupEdition'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/marker-groups',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/markerGroups/components/MarkerGroupsPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/marker-categories',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/markerCategory/components/MarkerCategoriesPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/marker-units',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/markerUnit/components/MarkerUnitsPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/marker-panels/:panelId',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/markerPanel/components/MarkerPanelPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/marker-panels',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/markerPanel/components/MarkerPanelsPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/user-analyses/:id/overview',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/userAnalyses/components/UserAnalysesOverviewPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/user-analyses/:id/edit',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/userAnalyses/components/UserAnalysesDetailPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/user-analyses',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/userAnalyses/components/UserAnalysesPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/tags',
    component: lazy(() => import('@features/tags/TagsPage')),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/article-categories',
    component: lazy(
      () =>
        import(
          '@features/articlesSection/articleCategory/ArticleCategoriesPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/articles/new',
    component: lazy(
      () => import('@features/articlesSection/article/components/CreateArticle')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/articles/:articleId',
    component: lazy(
      () => import('@features/articlesSection/article/components/ArticlePage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/articles',
    component: lazy(
      () => import('@features/articlesSection/article/components/ArticlesPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/surveys/:id',
    component: lazy(
      () => import('@features/surveySection/survey/components/SurveyDetailPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/surveys',
    component: lazy(
      () => import('@features/surveySection/survey/components/SurveysPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/logout',
    component: lazy(() => import('@features/auth/LogoutPage')),
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/login',
    component: lazy(() => import('@features/auth/LoginPage')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/error',
    component: lazy(() => import('../../../common/ErrorPage')),
    layout: 'BlankLayout',
    meta: {
      publicRoute: true
    }
  },
  {
    path: '/formulas/create',
    component: lazy(
      () =>
        import(
          '@features/analysesSection/formula/components/FormulaCreationPage'
        )
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/formulas/:id',
    component: lazy(
      () =>
        import('@features/analysesSection/formula/components/FormulaDetailPage')
    ),
    meta: {
      authRoute: true
    }
  },
  {
    path: '/formulas',
    component: lazy(
      () => import('@features/analysesSection/formula/components/FormulasPage')
    ),
    meta: {
      authRoute: true
    }
  }
]

if (NODE_ENV === 'development') {
  routes.push({
    path: '/test',
    layout: 'BlankLayout',
    component: lazy(() => import('../../../common/TestPage')),
    meta: {
      publicRoute: true
    }
  })
}

export { DefaultRoute, TemplateTitle, routes as Routes }
