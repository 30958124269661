import {
  camelizeRespDataKeys,
  customSnakecaseKeys,
  serializeFormData
} from '@common/utils'
import restApi from '@infrastructure/api/restApi'

const surveyApi = {
  create: (data) => restApi.post('/admin/surveys', data),
  getList: () => restApi.get('/admin/surveys').then(camelizeRespDataKeys),
  getPreviews: () =>
    restApi.get('/admin/surveys/previews').then(camelizeRespDataKeys),
  getDetailById: (id) =>
    restApi.get(`/admin/surveys/${id}`).then(camelizeRespDataKeys),
  updateById: (id, data) => restApi.put(`/admin/surveys/${id}`, data),
  deleteById: (id) => restApi.delete(`/admin/surveys/${id}`),
  cloneById: (id) =>
    restApi.post(`/admin/surveys/${id}/clone`).then(camelizeRespDataKeys),
  createQuestion: ({ surveyId, data }) =>
    restApi.post(
      `/admin/surveys/${surveyId}/questions`,
      serializeFormData(data)
    ),
  updateQuestion: ({ surveyId, id, data }) =>
    restApi.put(
      `/admin/surveys/${surveyId}/questions/${id}`,
      serializeFormData(data)
    ),
  setQuestionsOrder: ({ id, questions }) =>
    restApi.patch(`/admin/surveys/${id}/questions/order`, {
      questions
    }),
  deleteQuestion: ({ surveyId, id }) =>
    restApi.delete(`/admin/surveys/${surveyId}/questions/${id}`),
  createQuestionBioCondition: ({ surveyId, questionId, data }) =>
    restApi.post(
      `/admin/surveys/${surveyId}/questions/${questionId}/bio-conditions`,
      customSnakecaseKeys(data)
    ),
  deleteQuestionBioCondition: ({ surveyId, questionId, id }) =>
    restApi.delete(
      `/admin/surveys/${surveyId}/questions/${questionId}/bio-conditions/${id}`
    ),
  createQuestionQuestionAnswerCondition: ({ surveyId, questionId, data }) =>
    restApi.post(
      `/admin/surveys/${surveyId}/questions/${questionId}/answered-question-conditions`,
      customSnakecaseKeys(data)
    ),
  deleteQuestionQuestionAnswerCondition: ({ surveyId, questionId, id }) =>
    restApi.delete(
      `/admin/surveys/${surveyId}/questions/${questionId}/answered-question-conditions/${id}`
    ),
  createQuestionOption: ({ surveyId, questionId, data }) =>
    restApi.post(
      `/admin/surveys/${surveyId}/questions/${questionId}/options`,
      customSnakecaseKeys(data)
    ),
  updateQuestionOption: ({ surveyId, questionId, id, data }) =>
    restApi.put(
      `/admin/surveys/${surveyId}/questions/${questionId}/options/${id}`,
      customSnakecaseKeys(data)
    ),
  deleteQuestionOption: ({ surveyId, questionId, id }) =>
    restApi.delete(
      `/admin/surveys/${surveyId}/questions/${questionId}/options/${id}`
    ),
  setQuestionOptionsOrder: ({ surveyId, id, options }) =>
    restApi.patch(`/admin/surveys/${surveyId}/questions/${id}/options/order`, {
      options
    })
}

export default surveyApi
