// ** Checks if an object is empty (returns boolean)
import { format } from 'date-fns'
import {
  curryRight,
  differenceWith,
  get,
  isEmpty,
  isEqual,
  isNil,
  isPlainObject,
  pick,
  transform
} from 'lodash-es'

import { DATE_FORMATS, locales } from '@common/constants'
import { typeMap } from '@features/surveySection/surveyQuestion/service/surveyQuestionConstants'

export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
}
// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  {
    formatting = { month: 'numeric', day: 'numeric', year: 'numeric' },
    locale = 'uk'
  } = {}
) => {
  if (!value) return value
  return new Intl.DateTimeFormat(locale, formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getMultilangSetter = (multilangObj, fn) => {
  return (locale, value) => {
    if (!locale) return
    fn({ ...multilangObj, [locale]: value })
  }
}

export const isAllLocalesFilled = (multilangObj) => {
  let isValid = true

  locales.forEach((locale) => {
    if (!multilangObj[locale.code]) isValid = false
  })

  return isValid
}

export const wait = (time = 300) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

export const isSurveyQuestionWithOptions = (questionOrType) => {
  const type =
    typeof questionOrType === 'string' ? questionOrType : questionOrType.type

  return type === typeMap.radio || type === typeMap.checkbox
}

export const allowOnlyFullTranslationObj = (translationObj = {}) => {
  let isValid = true

  locales.forEach((locale) => {
    if (!translationObj[locale.code]) {
      isValid = false
    }
  })

  return isValid ? translationObj : null
}

export const getValuesFromSelected = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => getValuesFromSelected(item))
  }

  return data.value
}

export const confirmAction = (action, { message = 'Are you sure?' } = {}) => {
  // eslint-disable-next-line no-restricted-globals
  const openTime = new Date()
  const proceed = confirm(message)
  const closeTime = new Date()
  if (proceed) {
    action()
  } else {
    //make actions work when modal windows are disabled
    if (closeTime - openTime < 10) {
      action()
    }
  }
}

export const transformToFieldById = (arr, field, { valueKey = 'id' } = {}) =>
  arr.reduce((acc, item) => {
    acc[item[valueKey]] = item[field]
    return acc
  }, {})

export const transformToFieldsById = (arr, { valueKey = 'id' } = {}) =>
  arr.reduce((acc, item) => {
    const { [valueKey]: id, ...rest } = item
    acc[id] = rest
    return acc
  }, {})

export const transformToObjsByFieldAndId = (
  arr,
  fields = [],
  { valueKey = 'id' } = {}
) => {
  return fields.reduce((acc, field) => {
    acc[field] = {}
    arr.forEach(({ [valueKey]: id, ...rest }) => {
      if (rest[field]) {
        acc[field][id] = rest[field]
      }
    })
    return acc
  }, {})
}

export const customMapDataToOptions = (data, labelSelector, valueSelector) =>
  data?.map((item) => ({
    label: labelSelector(item),
    value: valueSelector(item)
  }))

export const mapDataToOptions = (
  data,
  { labelKey = 'title', valueKey = 'id' } = {}
) =>
  customMapDataToOptions(
    data,
    (item) => item?.[labelKey]?.uk,
    (item) => item?.[valueKey]
  )

export const optionsComparator = (a, b) => a.value !== b.value

export const findAddedDeletedItems = (
  incoming = [],
  base = [],
  { idProperty = 'id' } = {}
) => {
  const res = { added: null, deleted: null }
  res.added = differenceWith(
    incoming,
    base,
    (a, b) => a[idProperty] && a[idProperty] === b[idProperty]
  )
  res.deleted = differenceWith(
    base,
    incoming,
    (a, b) => a[idProperty] === b[idProperty]
  ).map((item) => item[idProperty])
  return res.added.length || res.deleted.length ? res : null
}

export const formDataDifference = (object, base) => {
  const diff = transform(object, function (result, value, key) {
    if (isPlainObject(value) && isPlainObject(base[key])) {
      if (!isEqual(value, base[key])) {
        result[key] = value
      }
    } else if (!isEqual(value, base[key])) {
      result[key] = value
    }
  })
  return isObjEmpty(diff) ? null : diff
}

export const fromDefaultValues = (data, base) =>
  isPlainObject(data)
    ? transform(
        base,
        (result, value, key) => {
          result[key] = data[key] ?? value
        },
        {}
      )
    : base

export const withPrefix = (string, prefix) =>
  prefix ? `${prefix}.${string}` : string

export const joinNonEmptyStrings = (delimiter, ...strings) => {
  if (!delimiter) {
    throw 'No delimiter'
  }
  return strings
    .filter(
      (string) =>
        !isEmpty(string) || (typeof string !== 'string' && !!string?.toString)
    )
    .join(delimiter)
}

export const dotsJoin = (...strings) => joinNonEmptyStrings('.', ...strings)

export const getDate = (date) => format(new Date(date), DATE_FORMATS.DATE)

export const getDateAndTime = (date) =>
  format(new Date(date), DATE_FORMATS.DATE_AND_TIME)

export const dateOrPlaceholder = (date, placeholder = '-') =>
  date ? getDateAndTime(date) : placeholder

export const dateSortFunction = (keyPath, rowA, rowB) => {
  return new Date(get(rowA, keyPath)) - new Date(get(rowB, keyPath))
}

export const dateColumnWithSort = (keyPath, colProps) => {
  return {
    selector: (row) => dateOrPlaceholder(get(row, keyPath)),
    sortFunction: (rowA, rowB) => dateSortFunction(keyPath, rowA, rowB),
    maxWidth: '150px',
    sortable: true,
    ...colProps
  }
}

export const getTrsColumns = (propName = 'title', columnProps = undefined) => {
  return locales.map((locale) => ({
    name: locale.code.toUpperCase(),
    selector: (row) =>
      row[propName]?.[locale.code] ? (
        row[propName]?.[locale.code]
      ) : (
        <span className="text-danger">MISSING</span>
      ),
    sortable: true,
    minWidth: '100px',
    ...columnProps
  }))
}

export const readFileBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    const [mime] = file.type.split('/')
    if (mime === 'image') {
      reader.addEventListener('load', () => {
        resolve(reader.result)
      })
      reader.addEventListener('error', () => {
        reject(reader.error)
      })
      reader.readAsDataURL(file)
    }
  })

export const dataToOption = (value, label) =>
  isNil(value) || isNil(label)
    ? ''
    : {
        value,
        label
      }

export const dataToOptions = (
  data,
  { labelKey = 'title', valueKey = 'id' } = {}
) => data?.map((data) => dataToOption(data[valueKey], data[labelKey])) || []

export const customDataToOptions = (
  data,
  { labelSelector = (data) => data, valueSelector = (data) => data } = {}
) =>
  data?.map((data, index) =>
    dataToOption(valueSelector(data, index), labelSelector(data, index))
  )

export const formOptionToData = (
  formOption,
  { raw = false, valueKey = 'id', labelKey = null } = {}
) =>
  raw
    ? !isPlainObject(formOption)
      ? formOption
      : formOption.value
    : Object.assign(
        {
          [valueKey]: !isPlainObject(formOption) ? formOption : formOption.value
        },
        labelKey && {
          [labelKey]: !isPlainObject(formOption) ? formOption : formOption.label
        }
      )

export const fromOptionsToData = (formOptions, options) =>
  formOptions.map((formOption) => formOptionToData(formOption, options))

export const fileBlobToUrl = (file) => {
  return window.URL.createObjectURL(new Blob([file]))
}

export const downloadFile = (axiosResponse) => {
  const url = fileBlobToUrl(axiosResponse.data)
  const filename =
    axiosResponse.headers['content-disposition'].split('filename=')[1]
  const link = document.createElement('a')
  link.download = filename
  link.href = url
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export const dispatchOptionsValues = (data) => {
  let result = data
  if (!isNil(data)) {
    if (isPlainObject(data)) {
      if (
        Object.prototype.hasOwnProperty.call(data, 'value') &&
        Object.prototype.hasOwnProperty.call(data, 'lable')
      ) {
        result = data.value
      } else {
        result = { ...data }
        for (const [key, value] of Object.entries(result)) {
          result[key] = dispatchOptionsValues(value)
        }
      }
    } else if (Array.isArray(data)) {
      result = data.map((item) => dispatchOptionsValues(item))
    }
  }
  return result
}

export const getItemTitle = (item) => get(item, 'title')

export const combineCbs =
  (...callbacks) =>
  (...args) => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        callback(...args)
      }
    }
  }

export const mergeCbProps = (...objects) => {
  if (!objects.length) {
    return null
  }
  const callbacksByKey = transform(
    objects,
    (acc, curr) => {
      if (!curr) {
        return
      }
      Object.entries(curr).forEach(([key, cb]) => {
        if (typeof cb !== 'function') {
          return
        }
        if (acc[key]) {
          acc[key].push(cb)
        } else {
          acc[key] = [cb]
        }
      })
    },
    {}
  )
  return transform(
    callbacksByKey,
    (acc, curr, key) => {
      acc[key] = combineCbs(...curr)
    },
    {}
  )
}

const pickMutationCbProps = curryRight(
  pick,
  2
)(['onSuccess', 'onError', 'onMutate'])

export const mergeMutationCbProps = (...objects) =>
  mergeCbProps(...objects.map(pickMutationCbProps))
