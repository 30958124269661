import { isNil } from 'lodash-es'

import { markerNormEntityName } from '@features/analysesSection/markerNorms/service/constants'
import markerNormApi from '@features/analysesSection/markerNorms/service/markerNormApi'
import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import { getEntityChangementMessage } from '@infrastructure/entityChangement/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import toast from '@infrastructure/toast/toast'

import markerRQKeys from '../reactQuery/markerRQKeys'
import { markerEntityName } from '../service/constants'

export default createEntityChangementConfig2({
  entity: markerEntityName,
  config: [
    {
      changements: ['created', 'updated', 'deleted'],
      listener: ({ name, type }) => {
        toast.info({
          title: getEntityChangementMessage(name, type)
        })
        const key = markerRQKeys.lists()
        queryClient.invalidateQueries(key)
      }
    },
    {
      changements: ['updated'],
      listener: ({ meta: { id } }) => {
        const key = markerRQKeys.detail(id)
        queryClient.invalidateQueries(key)
      }
    },
    {
      changements: ['deleted'],
      listener: ({ meta: { id } }) => {
        const key = markerRQKeys.detail(id)
        queryClient.removeQueries(key)
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: [markerNormEntityName],
      changements: ['created', 'updated'],
      listener: async ({ meta: { id, markerId } }) => {
        const key = markerRQKeys.detail(markerId)
        const marker = queryClient.getQueryData(key)
        if (marker) {
          const { norms } = marker
          const index = norms?.findIndex((item) => item.id === id)
          if (!isNil(index) && index !== -1) {
            const { data: newNorm } = await markerNormApi.getDetailById(id)
            const newNorms = [...norms]
            newNorms.splice(index, 1, newNorm)
            queryClient.setQueryData(key, {
              ...marker,
              norms: newNorms
            })
          } else {
            queryClient.invalidateQueries(key)
          }
        }
      }
    },
    {
      entities: [markerNormEntityName],
      changements: ['deleted'],
      listener: ({ meta: { id, markerId } }) => {
        const key = markerRQKeys.detail(markerId)
        const marker = queryClient.getQueryData(key)
        if (marker) {
          const { norms } = marker
          const index = norms?.findIndex((item) => item.id === id)
          if (!isNil(index) && index !== -1) {
            const newNorms = [...norms]
            newNorms.splice(index, 1)
            queryClient.setQueryData(key, {
              ...marker,
              norms: newNorms
            })
          } else {
            queryClient.invalidateQueries(key)
          }
        }
      }
    }
  ]
})
