import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import {
  selectLocale,
  selectLocales,
  setLocale
} from '@infrastructure/localization/_slice'

const LangSwitch = ({ className }) => {
  const locales = useSelector(selectLocales)
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()

  return (
    <div className={className}>
      {locales.map((loc) => (
        <Button.Ripple
          key={loc.code}
          color="flat-secondary"
          className={classnames({
            active: loc.code === locale.code,
            'btn-icon': true
          })}
          onClick={() => dispatch(setLocale(loc.code))}
        >
          {loc.code}
        </Button.Ripple>
      ))}
    </div>
  )
}

export default LangSwitch
