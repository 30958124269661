import restApi from '@infrastructure/api/restApi'

import { userSexMap } from '../common/constants'

export const getOverviews = () => restApi.get('/admin/users')

export const getPreviews = () => restApi.get('/admin/users/previews')

export const getDetailById = (id) => restApi.get(`/admin/users/${id}`)

export const makeSexTitle = (sex) =>
  sex === userSexMap.female ? 'F' : sex === userSexMap.male ? 'M' : 'M&F'
