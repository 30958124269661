import { lazy } from 'react'

import { Routes } from '@infrastructure/navigation/routes'
import verticalNavigation from '@infrastructure/navigation/vertical'

import { name } from '../repository'

const basePath = '/recommendation-group-assignment-automation'

Routes.push({
  path: basePath,
  component: lazy(
    () => import('../components/RecGroupAssignmentAutomationPage')
  ),
  meta: {
    authRoute: true
  }
})

verticalNavigation.automationsSection.children.push({
  id: name,
  title: 'Групи Рекомендацій',
  navLink: basePath
})
