import { forEach, groupBy, keyBy, merge, pick, transform } from 'lodash-es'
import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import recoveryStageBasisRepository from '@features/recoveryStageBasis/repository'
import {
  toastApiResponseMOptions,
  toastApiResponseQOptions
} from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  archiveGroupById,
  archiveIntervalById,
  archiveRecommendationById,
  archiveTodoById,
  createConcStage,
  createGrRecommendation,
  createRecTodo,
  createStgGroup,
  createTodoInterval,
  deleteById,
  deleteGroupById,
  deleteIntervalById,
  deleteRecommendationById,
  deleteStageById,
  deleteTodoById,
  finishCurrentStep,
  getDetailById,
  getOverviews,
  name,
  reorderConcStages,
  reorderGrRecommendations,
  reorderStgGroups,
  reorderTodoIntervals,
  startNextStep,
  updateDiagnosisById,
  updateGroupById,
  updateIntervalById,
  updateRecommendationById,
  updateStageById,
  updateTodoById
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  overviews: ({ query = {} } = {}) => [...keys.lists(), 'overviews', query],
  previews: () => [...keys.lists(), 'previews'],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id]
}

// const selectOverviewsDataTree = (data) => {
//   const { medicalConclusions, concStages } = data
//   const stagesByConclusionId = groupBy(concStages, 'conclusionId')
//   return medicalConclusions.map((conclusion) => ({
//     ...conclusion,
//     stages: stagesByConclusionId[conclusion.id]
//   }))
// }

const parseTrs = ({ data, pickTrs }) =>
  transform(
    groupBy(data, 'id'),
    (acc, data, id) => {
      acc.push({
        id,
        ...transform(
          data,
          (acc, tr) => {
            const { locale, ...rest } = tr
            forEach(pick(rest, pickTrs), (value, key) => {
              merge(acc, { [key]: { [locale]: value } })
            })
          },
          {}
        )
      })
    },
    []
  )

const parseAndMergeEntityTrs = ({ data, trs, ...rest }) => {
  const trsById = keyBy(parseTrs({ data: trs, ...rest }), 'id')
  return data.map((data) => ({ ...data, ...trsById[data.id] }))
}

const selectDetailDataTree = (data) => {
  let { stages, groups, recommendations } = data
  const {
    conclusion,
    stageTrs,
    groupTrs,
    recommendationTrs,
    todos,
    todoAsTasks,
    todoAsSupplements,
    todoWhens,
    intervals,
    ...rest
  } = data
  stages = parseAndMergeEntityTrs({
    data: stages,
    trs: stageTrs,
    pickTrs: ['title']
  })
  groups = parseAndMergeEntityTrs({
    data: groups,
    trs: groupTrs,
    pickTrs: ['title', 'description']
  })
  recommendations = parseAndMergeEntityTrs({
    data: recommendations,
    trs: recommendationTrs,
    pickTrs: ['title']
  })
  const stagesByConclusionId = groupBy(stages, 'conclusionId')
  const groupsByStageId = groupBy(groups, 'stageId')
  const recommendationsByGroupId = groupBy(recommendations, 'groupId')
  const todoWhensByTodoId = groupBy(todoWhens, 'todoId')
  const todoAsTaskById = keyBy(todoAsTasks, 'id')
  const todoAsSupplementById = keyBy(todoAsSupplements, 'id')
  const todosByRecommendationId = groupBy(
    todos.map(({ id, ...rest }) => ({
      id,
      ...rest,
      asTask: todoAsTaskById[id],
      asSupplement: todoAsSupplementById[id],
      whens: todoWhensByTodoId[id]
    })),
    'recommendationId'
  )
  const intervalsByTodoId = groupBy(intervals, 'todoId')
  return {
    ...conclusion,
    ...rest,
    stages:
      stagesByConclusionId[conclusion.id]?.map((stage) =>
        recoveryStageBasisRepository.createDataTree({
          stage,
          groupsByStageId,
          recommendationsByGroupId,
          todosByRecommendationId,
          intervalsByTodoId
        })
      ) ?? []
  }
}

export const useOverviewsQ = ({ query, ...options } = {}) =>
  useQuery({
    queryKey: keys.overviews({ query }),
    queryFn: () => getOverviews({ query }).then(getDataProp),
    // select: selectOverviewsDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useDetailByIdQ = (id, options) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDetailById(id).then(getDataProp),
    select: selectDetailDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useStartNextStepM = (options) =>
  useMutation({
    mutationFn: ({ id }) => startNextStep(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useFinishCurrentStepM = (options) =>
  useMutation({
    mutationFn: ({ id }) => finishCurrentStep(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateDiagnosisByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateDiagnosisById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateConcStageM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createConcStage(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateStageByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateStageById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteStageByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteStageById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderConcStagesM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderConcStages(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateStgGroupM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createStgGroup(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateGroupByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateGroupById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteGroupByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteGroupById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useArchiveGroupByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => archiveGroupById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderStgGroupsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderStgGroups(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateGrRecommendationM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createGrRecommendation(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateRecommendationById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteRecommendationById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useArchiveRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => archiveRecommendationById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderGrRecommendationsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderGrRecommendations(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateRecTodoM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createRecTodo(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateTodoById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteTodoById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useArchiveTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => archiveTodoById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateTodoIntervalM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createTodoInterval(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateIntervalById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteIntervalById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useArchiveIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => archiveIntervalById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderTodoIntervalsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderTodoIntervals(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

const medicalConclusionRQ = {
  useOverviewsQ
}

export default medicalConclusionRQ
