import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'

import {
  create,
  deleteById,
  getDetailById,
  getOverviews,
  getPreviews,
  name,
  updateById
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  overviews: () => [...keys.lists(), 'overviews'],
  previews: () => [...keys.lists(), 'previews'],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id]
}

export const useOverviewsQ = (options) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    ...options
  })

export const usePreviewsQ = (options) =>
  useQuery({
    queryKey: keys.previews(),
    queryFn: () => getPreviews().then(getDataProp),
    ...options
  })

export const useDetailByIdQ = (id, options) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDetailById(id).then(getDataProp),
    ...options
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options
  })

const todoTaskRQ = {
  usePreviewsQ
}

export default todoTaskRQ
