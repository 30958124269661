import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'
import {
  invalidateQueryKeys,
  removeQueryKeys
} from '@infrastructure/reactQuery/utils'

import { keys } from '../reactQuery'
import {
  groupName,
  intervalName,
  name,
  recommendationName,
  stageName,
  todoName
} from '../repository'

createEntityChangementConfig2({
  entity: name,
  config: [
    {
      changements: ['updated'],
      listener: ({ meta: { id } }) => {
        invalidateQueryKeys(keys.detail(id), queryClient)
      }
    },
    {
      changements: ['deleted'],
      listener: ({ meta: { id } }) => {
        removeQueryKeys([keys.detail(id)], queryClient)
      }
    },
    {
      changements: [
        'currentStepFinished',
        'nextStepStarted',
        'stagesReordered'
      ],
      listener: ({ meta: { id } }) => {
        queryClient.invalidateQueries({
          queryKey: keys.detail(id)
        })
      }
    },
    {
      changements: ['created', 'updated', 'deleted'],
      listener: () => {
        const keys = []
        keys.push(keys.list())
        invalidateQueryKeys(keys, queryClient)
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: [
        stageName,
        groupName,
        recommendationName,
        todoName,
        intervalName
      ],
      changements: ['created', 'updated', 'deleted', 'archived'],
      listener: ({ meta: { conclusionId } }) => {
        queryClient.invalidateQueries({
          queryKey: keys.detail(conclusionId)
        })
      }
    },
    {
      entities: [stageName],
      changements: ['groupsReordered'],
      listener: ({ meta: { conclusionId } }) => {
        queryClient.invalidateQueries({
          queryKey: keys.detail(conclusionId)
        })
      }
    },
    {
      entities: [groupName],
      changements: ['recommendationsReordered'],
      listener: ({ meta: { conclusionId } }) => {
        queryClient.invalidateQueries({
          queryKey: keys.detail(conclusionId)
        })
      }
    },
    {
      entities: [todoName],
      changements: ['intervalsReordered'],
      listener: ({ meta: { conclusionId } }) => {
        queryClient.invalidateQueries({
          queryKey: keys.detail(conclusionId)
        })
      }
    }
  ]
}).init()
