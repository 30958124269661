import { isEqual, keyBy } from 'lodash-es'

import { locales } from '@common/constants'
import { createSlice } from '@reduxjs/toolkit'

const currentLSKey = 'userLocale'

const localeByCode = keyBy(locales, 'code')

const isMatchesLocale = (locale) => {
  if (!locale?.code) {
    return false
  }
  const origLocale = localeByCode[locale.code]
  if (!origLocale) {
    return false
  }
  return isEqual(origLocale, locale)
}

const getLocale = () => {
  try {
    const lsLocale = JSON.parse(localStorage.getItem(currentLSKey))
    if (isMatchesLocale(lsLocale)) {
      return lsLocale
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return locales[0]
}

const initialState = {
  list: locales,
  current: getLocale()
}

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale(state, action) {
      const locale = locales.find((item) => item.code === action.payload)
      state.current = locale || locales[0]
      localStorage.setItem(currentLSKey, JSON.stringify(state.current))
    }
  }
})

export const { setLocale } = localeSlice.actions
export const selectLocale = (state) => state.locale.current
export const selectLocales = (state) => state.locale.list
export const localeReducer = localeSlice.reducer
