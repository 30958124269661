import restApi from '@infrastructure/api/restApi'

export const entityName = 'promocode'

export const typeMap = {
  percentage: 'percentage',
  fixed: 'fixed'
}

export const create = (data) => restApi.post('/admin/promocodes', data)
export const getList = () => restApi.get(`/admin/promocodes`)
export const getPreviewList = () => restApi.get(`/admin/promocodes/previews`)
export const updateById = (id, data) =>
  restApi.put(`/admin/promocodes/${id}`, data)
export const deleteById = (id) => restApi.delete(`/admin/promocodes/${id}`)

export default { entityName }
