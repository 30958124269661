import classNames from 'classnames'

import ComponentSpinner from '@components/spinner/Loading-spinner'

const BBLoader = ({ className, inline, big }) => {
  const classes = classNames('bb-loader', className, {
    inline,
    big
  })

  return (
    <div className={classes}>
      <ComponentSpinner />
    </div>
  )
}

export default BBLoader
