import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getDataProp, getRespData } from '@common/utils'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { toastApiResponseQOptions } from '@src/utility/apiUtils'

import { getDetailById, getOverviews, getPreviews } from '../repository'
import userApi from '../service/userApi'

export const keys = {
  all: ['user'],
  lists: () => [...keys.all, 'lists'],
  overviews: () => [...keys.lists(), 'overviews'],
  details: () => [...keys.all, 'detail'],
  detail: (id) => [...keys.details(), id],
  meta: (id) => [...keys.all, 'meta', id],
  previews: () => [...keys.lists(), 'previews'],
  overview: (id) => [...keys.all, 'overview', id],
  analyses: (userId) => [...keys.detail(userId), 'analyses'],
  surveys: (userId) => [...keys.detail(userId), 'surveys']
}

export const useOverviewsQ = () =>
  useQuery(keys.overviews(), () => getOverviews().then(getRespData))

export const usePreviewsQ = (options) =>
  useQuery({
    queryKey: keys.previews(),
    queryFn: () => getPreviews().then(getDataProp),
    ...options,
    ...toastApiResponseQOptions
  })

export const useGetOverviewDataQ = (id, options = undefined) =>
  useQuery(
    keys.overview(id),
    () => userApi.getOverviewData(id).then(getRespData),
    options
  )

export const useDetailByIdQ = (id, options) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDetailById(id).then(getDataProp),
    ...options
  })

export const useUpdateUserM = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id, data }) => userApi.updateUser(id, data), {
    onSuccess: (res, { id, data }) => {
      queryClient.invalidateQueries(keys.overviews())
      queryClient.setQueryData(keys.detail(id), (oldData) => ({
        ...oldData,
        ...data
      }))
    }
  })
}

export const useDeleteUserM = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => userApi.deleteUser(id), {
    onSuccess: (data, { id }) => {
      queryClient.removeQueries(keys.detail(id))
      queryClient.setQueryData(keys.overviews(), (oldData) =>
        oldData.filter((data) => data.id !== id)
      )
    }
  })
}

export const usePatchM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => userApi.patch(id, data),
    ...options
  })

const userRQ = {
  useDetailByIdQ,
  usePreviewsQ
}

export default userRQ
