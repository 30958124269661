import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import { getEntityChangementMessage } from '@infrastructure/entityChangement/utils'
import queryClient from '@infrastructure/reactQuery/queryClient'
import toast from '@infrastructure/toast/toast'

import markerGroupRQKeys from '../reactQuery/markerGroupRQKeys'
import { entityName } from '../service/markerGroupHelpers'

export default createEntityChangementConfig2({
  entity: entityName,
  config: [
    {
      changements: ['created', 'updated', 'deleted'],
      listener: ({ name, type }) => {
        toast.info({
          title: getEntityChangementMessage(name, type)
        })
        const key = markerGroupRQKeys.list()
        queryClient.invalidateQueries(key)
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: ['marker'],
      changements: ['created', 'updated', 'deleted'],
      listener: async () => {
        const key = markerGroupRQKeys.list()
        queryClient.invalidateQueries(key)
      }
    }
  ]
})
