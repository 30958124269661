import { groupBy } from 'lodash-es'
import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import recoveryStageBasisRepository from '@features/recoveryStageBasis/repository'
import {
  toastApiResponseMOptions,
  toastApiResponseQOptions
} from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  create,
  createGrRecommendation,
  createRecTodo,
  createStgGroup,
  createTodoInterval,
  deleteById,
  deleteGroupById,
  deleteIntervalById,
  deleteRecommendationById,
  deleteTodoById,
  getDetailById,
  getOverviews,
  getPreviews,
  name,
  reorderGrRecommendations,
  reorderStgGroups,
  reorderTodoIntervals,
  updateById,
  updateGroupById,
  updateIntervalById,
  updateRecommendationById,
  updateTodoById
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  overviews: () => [...keys.lists(), 'overviews'],
  previews: () => [...keys.lists(), 'previews'],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id]
}

const selectOverviews = (data) => {
  const { recoveryStagePrototypes, stgGroups } = data
  const groupsByStageId = groupBy(stgGroups, 'stageId')
  return recoveryStagePrototypes.map((stage) => ({
    ...stage,
    groups: groupsByStageId[stage.id] ?? []
  }))
}

const selectDetail = (data) => {
  const {
    recoveryStagePrototype,
    stgGroups,
    grRecommendations,
    recTodos,
    todoIntervals
  } = data

  const groupsByStageId = groupBy(stgGroups, 'stageId')
  const recommendationsByGroupId = groupBy(grRecommendations, 'groupId')
  const todosByRecommendationId = groupBy(recTodos, 'recommendationId')
  const intervalsByTodoId = groupBy(todoIntervals, 'todoId')
  return recoveryStageBasisRepository.createDataTree({
    stage: recoveryStagePrototype,
    groupsByStageId,
    recommendationsByGroupId,
    todosByRecommendationId,
    intervalsByTodoId
  })
}

export const usePreviewsQ = (options) =>
  useQuery({
    queryKey: keys.previews(),
    queryFn: () => getPreviews().then(getDataProp),
    ...options,
    ...toastApiResponseQOptions
  })

export const useOverviewsQ = (options) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    select: selectOverviews,
    ...options,
    ...toastApiResponseQOptions
  })

export const useDetailByIdQ = (id, options) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDetailById(id).then(getDataProp),
    select: selectDetail,
    ...options,
    ...toastApiResponseQOptions
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })
export const useCreateStgGroupM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createStgGroup(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateGroupByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateGroupById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteGroupByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteGroupById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderStgGroupsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderStgGroups(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateGrRecommendationM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createGrRecommendation(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateRecommendationById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteRecommendationByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteRecommendationById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderGrRecommendationsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderGrRecommendations(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateRecTodoM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createRecTodo(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateTodoById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteTodoById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateTodoIntervalM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createTodoInterval(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateIntervalById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteIntervalById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderTodoIntervalsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderTodoIntervals(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })
