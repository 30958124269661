export const intervalTypeMap = {
  exact: 'exact',
  near: 'near',
  far: 'far'
}

export const markDirectionMap = {
  up: 'up',
  down: 'down'
}

export const transformToInterval = ({
  valueFrom = -Infinity,
  valueTo = +Infinity,
  type = intervalTypeMap.near
} = {}) => ({
  valueFrom: valueFrom ?? -Infinity,
  valueTo: valueTo ?? +Infinity,
  type
})
