import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import { intervalName, name } from '../repository'

export default createEntityChangementConfig2({
  entity: name,
  config: [
    {
      changements: ['created', 'updated', 'deleted', 'intervalsReordered'],
      listener: () => {
        queryClient.invalidateQueries({
          queryKey: keys.lists()
        })
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: [intervalName],
      changements: ['created', 'updated', 'deleted'],
      listener: () => {
        console.log('here')
        queryClient.invalidateQueries({
          queryKey: keys.lists()
        })
      }
    }
  ]
}).init()
