import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'

import { keys } from '../reactQuery'
import { name, prototypeName } from '../repository'

createEntityChangementConfig2({
  entity: name,
  config: [
    {
      changements: [
        'created',
        'archived',
        'updated',
        'deleted',
        'prototypesReordered'
      ],
      listener: () => {
        queryClient.invalidateQueries(keys.overviews())
      }
    }
  ],
  dependenciesConfig: [
    {
      entities: [prototypeName],
      changements: ['created', 'archived', 'deleted'],
      listener: () => {
        queryClient.invalidateQueries(keys.overviews())
      }
    }
  ]
}).init()
