import restApi from '@infrastructure/api/restApi'

export const name = 'supplement'

export const create = (fields) => restApi.post('/admin/supplements', fields)

export const getOverviews = () => restApi.get(`/admin/supplements`)

export const getPreviews = () => restApi.get(`/admin/supplements/previews`)

export const getDoses = () => restApi.get(`/admin/supplements/doses`)

export const getDetailById = (id) => restApi.get(`/admin/supplements/${id}`)

export const updateById = (id, fields) =>
  restApi.put(`/admin/supplements/${id}`, fields)

export const deleteById = (id) => restApi.delete(`/admin/supplements/${id}`)

export const makeDosePreviewTitle = ({ value, unit }) => {
  return `${value}${unit.title}`
}

const supplementRepository = {
  makeDosePreviewTitle
}

export default supplementRepository
