import { articlesReducer } from '@features/articlesSection/article/redux/articleSlice'
import { authActions, authReducer } from '@features/auth/_slice'
import authService, { AUTH_EVENTS } from '@features/auth/authService'
import { localeReducer } from '@infrastructure/localization/_slice'
import { configureStore } from '@reduxjs/toolkit'

import layoutReducer from '../layouts/layoutSlice'

export const store = configureStore({
  devTools: true,
  reducer: {
    locale: localeReducer,
    layout: layoutReducer,
    auth: authReducer,
    articles: articlesReducer
  }
})

authService.initialize()

authService.addListener(AUTH_EVENTS.initialized, () => {
  store.dispatch(authActions.initialized())
})
authService.addListener(AUTH_EVENTS.login, () => {
  store.dispatch(authActions.login())
})
authService.addListener(AUTH_EVENTS.logout, () => {
  store.dispatch(authActions.logout())
})
authService.addListener(AUTH_EVENTS.error, () => {
  store.dispatch(authActions.error())
})
