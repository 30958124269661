import restApi from '@infrastructure/api/restApi'

export const getOverviews = ({ query } = {}) =>
  restApi.get('/admin/user-analyses', { params: query })

export const getPreviews = ({ query } = {}) =>
  restApi.get('/admin/user-analyses/previews', { params: query })

export const getDatePreviews = ({ query } = {}) =>
  restApi.get('/admin/user-analyses/dates/previews', { params: query })
