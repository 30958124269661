import todoBasisRepository from '@features/todoBasis/repository'
import { fromDefaultValues } from '@utils'

export const makeShortTitle = (data) =>
  `${data.title.uk} (завдань: ${data.todos.length})`

export function createDataTree({
  recommendation,
  todosByRecommendationId,
  intervalsByTodoId
}) {
  return {
    ...recommendation,
    todos:
      todosByRecommendationId?.[recommendation.id]?.map((todo) =>
        todoBasisRepository.createDataTree({ todo, intervalsByTodoId })
      ) ?? []
  }
}

export const defaultEValues = {
  title: {
    en: '',
    uk: ''
  },
  descriptionArticleId: null
}

export const defaultCValues = {
  ...defaultEValues,
  todos: []
}

export const transformToCFormData = (data) => {
  const formData = fromDefaultValues(data, defaultCValues)
  return {
    ...formData,
    todos: formData.todos.map(todoBasisRepository.transformToCFormData)
  }
}

const recommendationBasisRepository = {
  createDataTree,
  makeShortTitle,
  defaultCValues,
  defaultEValues,
  transformToCFormData
}

export default recommendationBasisRepository
