import { Divide, Minus, Plus, X } from 'react-feather'

import { fromDefaultValues } from '@utils'

const operatorRegExp = /[/*+-]/g

const namePattern = '[a-zA-Z]+[a-zA-Z0-9]*'

const nameRegExp = new RegExp(namePattern)

const matchNameRegExp = new RegExp(namePattern, 'g')

const componentByOperator = {
  '/': Divide,
  '*': X,
  '+': Plus,
  '-': Minus
}

const defaultData = {
  name: '',
  markerId: '',
  unitId: ''
}

const transformToFormData = (data = {}) => fromDefaultValues(data, defaultData)

const tranformToPreviewData = (
  { markerId, unitId, ...rest },
  markers,
  units
) => {
  const marker = markers.find((item) => item.id === markerId)
  const unit = units.find((item) => item.id === unitId)
  return { ...rest, marker, unit }
}

const createVariableName = (order) => `m${order}`

export default {
  operatorRegExp,
  matchNameRegExp,
  nameRegExp,
  namePattern,
  componentByOperator,
  transformToFormData,
  tranformToPreviewData,
  createVariableName
}
