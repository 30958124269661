import { camelizeRespDataKeys, customSnakecaseKeys } from '@common/utils'
import restApi from '@infrastructure/api/restApi'

const markerCategoryApi = {
  create: (fields) =>
    restApi.post('/admin/marker-categories', customSnakecaseKeys(fields)),
  getList: () =>
    restApi.get(`/admin/marker-categories`).then(camelizeRespDataKeys),
  getPreviewList: () =>
    restApi.get(`/admin/marker-categories/previews`).then(camelizeRespDataKeys),
  getDetailById: (id) =>
    restApi.get(`/admin/marker-categories/${id}`).then(camelizeRespDataKeys),
  updateById: (id, fields) =>
    restApi
      .put(`/admin/marker-categories/${id}`, customSnakecaseKeys(fields))
      .then(({ data }) => data),
  deleteById: (id) => restApi.delete(`/admin/marker-categories/${id}`)
}

export default markerCategoryApi
