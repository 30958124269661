import recommendationBasisRepository from '@features/recommendationBasis/repository'
import { fromDefaultValues } from '@utils'

export const parceCEDataToData = ({ image, ...rest }) => ({
  ...rest,
  imageUrl: image?.url ?? null,
  imageFile: image?.file ?? null
})

export const makeShortTitle = (data) =>
  `${data.title.uk} (рекомендацій: ${data.recommendations.length})`

export function createDataTree({
  group,
  recommendationsByGroupId,
  todosByRecommendationId,
  intervalsByTodoId
}) {
  return {
    ...group,
    recommendations:
      recommendationsByGroupId[group.id]?.map((recommendation) =>
        recommendationBasisRepository.createDataTree({
          recommendation,
          todosByRecommendationId,
          intervalsByTodoId
        })
      ) ?? []
  }
}

export const defaultEValues = {
  title: {
    en: '',
    uk: ''
  },
  description: {
    en: '',
    uk: ''
  },
  image: null
}

export const defaultCValues = {
  ...defaultEValues,
  recommendations: []
}

export const transformToEFormData = (
  data,
  { defaultValues = defaultEValues, shouldTransformImage = true } = {}
) => {
  const { image, ...eData } = fromDefaultValues(data, defaultValues)
  return shouldTransformImage
    ? {
        ...eData,
        image: data.imageFile
          ? { file: data.imageFile }
          : data.imageUrl
          ? { url: data.imageUrl }
          : null
      }
    : {
        ...eData,
        imageFile: data.imageFile,
        imageUrl: data.imageUrl
      }
}

export const transformToCFormData = (
  data,
  { defaultValues = defaultCValues, shouldTransformImage = true } = {}
) => {
  const eData = transformToEFormData(data, {
    defaultValues,
    shouldTransformImage
  })
  return {
    ...eData,
    recommendations:
      data.recommendations?.map(
        recommendationBasisRepository.transformToCFormData
      ) ?? []
  }
}

const recGroupBasisRepository = {
  defaultCValues,
  defaultEValues,
  createDataTree,
  parceCEDataToData,
  makeShortTitle,
  transformToEFormData,
  transformToCFormData
}

export default recGroupBasisRepository
