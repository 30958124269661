import camelcaseKeys from 'camelcase-keys'
import { format, formatISO } from 'date-fns'
import { uk } from 'date-fns/locale'
import {
  get,
  intersection,
  isDate,
  isEqualWith,
  isNil,
  isNumber,
  isPlainObject,
  isString,
  kebabCase,
  keyBy,
  merge,
  omit,
  pick,
  property,
  snakeCase,
  transform
} from 'lodash-es'
import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'

import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { dotsJoin, joinNonEmptyStrings } from '@utils'

import {
  PIXEL_SIZE_FOR_BUTTON_ICONS_BY_BUTTON_SIZE,
  dateFormatMap
} from './constants'

export const getItemKey = (key) => (item) => get(item, key)

export const getItemIdKey = getItemKey('id')

export const getItemTitleKey = getItemKey('title')

export const getButtonIconSize = (buttonSize) =>
  PIXEL_SIZE_FOR_BUTTON_ICONS_BY_BUTTON_SIZE[buttonSize] ||
  PIXEL_SIZE_FOR_BUTTON_ICONS_BY_BUTTON_SIZE.default

export const camelizeRespDataKeys = (resp) => {
  resp.data = camelcaseKeys(resp.data, { deep: true })
  return resp
}

export const snakecaseDataKeys = (data) => snakecaseKeys(data, { deep: true })

const notIntRegex = /[^\d]+/gm

export const clearIntegerString = (str) => {
  if (typeof str !== 'string' || !str) {
    return ''
  }
  return str.replace(notIntRegex, '')
}

export const clearFloatString = (str) => {
  if (typeof str !== 'string' || !str) {
    return ''
  }
  const firstDotIndex = str.indexOf('.')
  let newStr = str.replace(notIntRegex, '')
  if (firstDotIndex !== -1) {
    newStr = `${newStr.slice(0, firstDotIndex)}.${newStr.slice(firstDotIndex)}`
  }
  return newStr
}

export const uncompletedFloatRegex = /([.]$)|([.][0-9]*0$)/

export const isCompleteFloatStr = (str) => {
  return !!str.length && !uncompletedFloatRegex.test(str)
}

export const parseUncompletedFloatStr = (str) => {
  if (!str) {
    return null
  }
  const completedFloatStr = str.replace(uncompletedFloatRegex, '')
  if (!completedFloatStr) {
    return null
  }
  return Number.parseFloat(completedFloatStr)
}

export const stringToNumber = (
  number,
  { isFloat = false, shouldClearString = true } = {}
) => {
  if (isNumber(number)) {
    return number
  }
  if (isNil(number)) {
    return NaN
  }
  if (isString(number)) {
    return isFloat
      ? parseFloat(shouldClearString ? clearFloatString(number) : number)
      : parseInt(shouldClearString ? clearIntegerString(number) : number)
  }
  return NaN
}

export const boundNumber = (number, { min = -Infinity, max = Infinity }) => {
  if (typeof number !== 'number' || !Number.isFinite(number)) {
    return isFinite(min) ? min : NaN
  }
  if (number < min) {
    return min
  }
  if (number > max) {
    return max
  }
  return number
}

export const boundNumericString = (
  string,
  { min = -Infinity, max = Infinity }
) => {
  if (typeof string !== 'string' || !string) {
    return isFinite(min) ? min.toString() : ''
  }
  const isEndsWithDot = string.endsWith('.')
  if (isEndsWithDot && string.length === 1) {
    return string
  }
  const num = boundNumber(Number(string), { min, max })
  return Number.isNaN(num)
    ? ''
    : isEndsWithDot
    ? num.toString().concat('.')
    : num.toString()
}

export const isNumberInBounds = (
  number,
  { min = -Infinity, max = Infinity }
) => {
  if (typeof number !== 'number' || !Number.isFinite(number)) {
    return false
  }
  if (number < min) {
    return false
  }
  if (number > max) {
    return false
  }
  return true
}

export const valueOrSelector = (selector, ...args) => {
  switch (typeof selector) {
    case 'function': {
      return selector(...args)
    }
    default: {
      return selector
    }
  }
}

export const listByIdSelector = createSelector(
  (state) => state,
  (state) => keyBy(state, 'id')
)

export const splitObject = (object, keys, { type = 'include' } = {}) => {
  if (!object || !type) {
    return []
  }
  let o1 = undefined
  let o2 = undefined
  switch (type) {
    default:
    case 'include': {
      o1 = pick(object, keys)
      o2 = omit(object, keys)
      break
    }
    case 'exclude': {
      o1 = omit(object, keys)
      o2 = pick(object, keys)
      break
    }
  }
  return [o1, o2]
}

export const customSnakecaseKeys = (obj) =>
  transform(obj, (acc, curr, key) => {
    acc[snakeCase(key)] =
      isPlainObject(curr) || Array.isArray(curr)
        ? customSnakecaseKeys(curr)
        : curr
  })

export const kebabcaseKeys = (obj) =>
  transform(obj, (acc, curr, key) => {
    acc[kebabCase(key)] =
      isPlainObject(curr) || Array.isArray(curr) ? kebabcaseKeys(curr) : curr
  })

const opts = { indices: true, noFilesWithArrayNotation: true }

export const serializeFormData = (
  data,
  { transformToSnakecaseKeys = true, formData = undefined } = {}
) => {
  if (transformToSnakecaseKeys) {
    return serialize(customSnakecaseKeys(data), opts, formData)
  } else {
    return serialize(data, opts, formData)
  }
}

export const getBooleanColumn = (
  path,
  name,
  { columnProps = undefined } = {}
) => ({
  name,
  selector: (row) => (get(row, path) ? 'yes' : 'no'),
  sortable: true,
  width: '100px',
  ...columnProps
})

export const getUrlFilename = (url) => {
  if (typeof url !== 'string' || !url) {
    return null
  }
  const segments = new URL(url).pathname.split('/')
  return segments.pop() || segments.pop()
}

export const splitStringOnTwoSlices = (
  string,
  delimiter,
  { removeDelimiter = true } = {}
) => {
  if (!isString(string) || !isString(delimiter)) {
    throw 'String or delimiter is not a string'
  }
  const index = string.indexOf(delimiter)
  if (index === -1) {
    return [string, '']
  }
  const left = string.slice(0, index)
  const right = string.slice(removeDelimiter ? index + delimiter.length : index)
  return [left, right]
}

const collectItemPathsFromArrayPathHelper = (obj, pathSteps, pathPrefix) => {
  const currArrayPath = pathSteps[0]
  const data = get(obj, currArrayPath)
  if (pathSteps.length > 1) {
    return (
      data?.flatMap((item, index) =>
        collectItemPathsFromArrayPathHelper(
          item,
          pathSteps.slice(1),
          dotsJoin(pathPrefix, currArrayPath, index)
        )
      ) ?? []
    )
  } else {
    return (
      data?.map((_, index) => dotsJoin(pathPrefix, currArrayPath, index)) ?? []
    )
  }
}

const trimDotsRegex = /(^\.)|(\.$)/

export const collectItemPathsFromGenericPath = (obj, genericPath) => {
  let paths
  const pathSteps = genericPath
    .split('[]')
    .map((path) => path.replace(trimDotsRegex, ''))
  const keyPath = pathSteps.pop()
  if (pathSteps.length) {
    paths = collectItemPathsFromArrayPathHelper(obj, pathSteps, '')
  } else {
    paths = ['']
  }
  return paths.map((path) => dotsJoin(path, keyPath))
}

export const collectItemsFromGenericPath = (
  obj,
  genericPath,
  { shouldCollectItemCb = undefined } = {}
) => {
  return collectItemPathsFromGenericPath(obj, genericPath).reduce(
    (acc, path) => {
      const item = get(obj, path)
      const shouldCollect = shouldCollectItemCb?.(item) ?? true
      if (shouldCollect) {
        acc.push(item)
      }
      return acc
    },
    []
  )
}

export const indexOfNthMatch = (str, pat, nth) => {
  const length = str.length
  let index = -1
  while (nth-- && index++ < length) {
    index = str.indexOf(pat, index)
    if (index < 0) break
  }
  return index
}

export const lastIndexOfNthMatch = (str, pat, nth) => {
  let index = -1
  while (nth++ && index-- >= 0) {
    index = str.lastIndexOf(pat, index)
    if (index < 0) break
  }
  return index
}

export const getRespData = (resp) => resp.data

export const mergeIfNotNil = (source, defaults) => {
  if (isNil(source)) {
    return null
  }
  if (typeof source !== 'object') {
    return { ...defaults }
  }
  return merge({}, defaults, source)
}

export const mergeIfAnyOfKeysProvided = (source, defaults, ...keys) => {
  if (!source) {
    return null
  }
  keys = keys.length ? keys.flat() : Object.keys(defaults)
  return intersection(Object.keys(source), keys).length !== 0
    ? { ...defaults, ...source }
    : null
}

export const mergeIfEachKeyProvided = (source, defaults, ...keys) => {
  keys = keys.length ? keys.flat() : Object.keys(defaults)
  return intersection(Object.keys(source), keys).length === keys.length
    ? { ...defaults, ...source }
    : null
}

export const getIsElementOverflown = ({
  clientWidth,
  clientHeight,
  scrollWidth,
  scrollHeight
}) => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth
}

export const getLabelProp = property('label')

export const getValueProp = property('value')

export const getIdProp = property('id')

export const getTitleProp = property('title')

export const getTitleUkProp = property('title.uk')

export const getDataProp = property('data')

const ignoreKeysList = ['_owner', '$$typeof']

export const isComponentPropsEqual = (prev, next) =>
  isEqualWith(prev, next, (prevValue, nextValue, key) => {
    return ignoreKeysList.includes(key) ? true : undefined
  })

export const pickIdProp = (data) => pick(data, 'id')

export const byId = (data) => keyBy(data, 'id')

export const booleanToString = (bool) => (bool ? 'Yes' : 'No')

export const transformSingleValueIdProp = {
  in: (value) => (value ? { id: value } : null),
  out: (option) => option.id
}

export const transformMultiValueIdProp = {
  in: (values) => values?.map((value) => ({ id: value.articleId })),
  out: (options) => options.map((option) => ({ id: option.id }))
}

export const moveImmutable = (array, from, to) => {
  const newArr = [...array]
  newArr.splice(to, 0, newArr.splice(from, 1)[0])
  return newArr
}

export const transformMediaUrl = (url) =>
  url && {
    url
  }

export const makeTitleAndNotePropsTitle = ({ title, note }) =>
  title.concat(note ? ` (${note})` : '')

export const setRHFValuesFromObject = (
  obj,
  setValue,
  options,
  { keyPath = '' } = {}
) =>
  Object.entries(obj).map(([key, value]) =>
    typeof value === 'object' && isDate(value)
      ? setRHFValuesFromObject(value, setValue, options, {
          keyPath: joinNonEmptyStrings('.', keyPath, key)
        })
      : setValue(key, value, options)
  )

export const makeImageThumbUrl = (url, { size = '360' } = {}) =>
  `${url}?thumbnail=${size}x${size}`

export const makeTitleAndOrderPropsTitle = ({ title, order }) =>
  `${order + 1}. ${title}`

export const formatUkDateTime = (date) =>
  format(date, dateFormatMap.dateTime, {
    locale: uk
  })

export const formatUkDate = (date) =>
  format(date, dateFormatMap.dateDotDLYear, {
    locale: uk
  })

export const formatISODate = (date) =>
  formatISO(date, { representation: 'date' })

export const propToISODate = (path) => (data) => formatISODate(get(data, path))

export const datePropToISODate = propToISODate('date')

export const commonEntityAdapter = createEntityAdapter()
