export const userSexMap = {
  male: 'male',
  female: 'female'
}

export const userAgeUnitMap = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year'
}
export const USER_ENTITY = 'user'

export const userSexes = Object.values(userSexMap)
