import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'
import toast from '@infrastructure/toast/toast'

import { keys } from '../reactQuery'
import userAnalysesHelpers from '../service/userAnalysesHelpers'

export default createEntityChangementConfig2({
  entity: userAnalysesHelpers.entityName,
  config: [
    {
      changements: ['created'],
      listener: ({ meta }) => {
        const { email } = meta?.user ?? {}
        toast.info({
          title: `Analyses for user ${email} has been created`
        })
      }
    },
    {
      changements: ['updated'],
      listener: ({ meta }, { isInternal }) => {
        const { id, user: { email } = {} } = meta ?? {}
        if (!isInternal) {
          toast.info({
            title: `Analyses for user ${email} has been updated`
          })
        }
        queryClient.invalidateQueries(keys.detail(id))
      }
    },
    {
      changements: ['deleted'],
      listener: ({ meta }) => {
        const { id, email } = meta
        toast.info({
          title: `Analyses for user ${email} has been deleted`
        })
        queryClient.removeQueries(keys.detail(id))
      }
    },
    {
      changements: ['examined'],
      listener: ({ meta }) => {
        const { id, examinedAt } = meta
        queryClient.setQueryData(keys.detail(id), (oldData) => ({
          ...oldData,
          examinedAt
        }))
      }
    },
    {
      changements: ['created', 'updated', 'deleted'],
      listener: () => {
        queryClient.invalidateQueries(keys.overviews())
      }
    }
  ]
})
