import { camelizeRespDataKeys } from '@common/utils'
import restApi from '@infrastructure/api/restApi'

const userApi = {
  getUserList: () =>
    restApi.post('/admin/users/find').then(camelizeRespDataKeys),
  getUserById: (id) =>
    restApi.get(`/admin/users/${id}`).then(camelizeRespDataKeys),
  deleteUser: (id) => restApi.delete(`/admin/users/${id}`),
  updateUser: (id, fields) => restApi.put(`/admin/users/${id}`, fields),
  getOverviewData: (id) =>
    restApi
      .get(`/admin/users/${id}/assignments-overview`)
      .then(camelizeRespDataKeys),
  getAnalyses: (userId) =>
    restApi.get(`/admin/users/${userId}/analyses`).then(({ data }) => {
      return data
    }),
  getSurveys: (userId) =>
    restApi.get(`/admin/users/${userId}/surveys`).then(({ data }) => {
      return data
    }),
  patch: (id, data) => restApi.patch(`/admin/users/${id}`, data)
}

export default userApi
