import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  isError: null
}

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    initialized: (state) => {
      state.isInitialized = true
    },
    login: (state) => {
      state.isLoggedIn = true
      state.isError = false
    },
    logout: (state) => {
      state.isLoggedIn = false
      state.isError = false
    },
    error: (state) => {
      state.isLoggedIn = false
      state.isError = true
    }
  }
})

export const authActions = authSlice.actions

export const authReducer = authSlice.reducer

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn
export const selectIsInitialized = (state) => state.auth.isInitialized
export const selectAuthError = (state) => state.auth.error
