// export const questionActionMap = {
//   setUserCity: 'set_user_city',
//   setUserFirstName: 'set_user_first_name',
//   setUserBirthday: 'set_user_birthday',
//   setUserSex: 'set_user_sex',
//   setUserPreferredLocale: 'set_user_preferred_locale'
// }
import { pick } from 'lodash-es'

import { mathConditionMap, optionByMathCondition } from '@common/constants'

export const typeMap = {
  text: 'text',
  date: 'date',
  radio: 'radio',
  checkbox: 'checkbox',
  textarea: 'textarea',
  rating: 'rating',
  yesno: 'yesno',
  media: 'media'
}

export const answerTypeMap = {
  optionId: 'option_id',
  otherOption: 'other_option',
  noneOfTheAboveOption: 'none_of_the_above_option'
}

export const questionAnswerConditionMap = {
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  GT: 'GT',
  LT: 'LT',
  GTE: 'GTE',
  LTE: 'LTE',
  GTE_YEARS: 'GTE_YEARS',
  LTE_YEARS: 'LTE_YEARS',
  EQUALS: 'EQUALS',
  NOT_EQUALS: 'NOT_EQUALS'
}

export const bioConditionAgeConditionMap = pick(mathConditionMap, 'gt', 'lt')

export const bioConditionAgeConditionOptions = Object.values(
  pick(optionByMathCondition, 'gt', 'lt')
)

export const ratingTypeMap = {
  number: 'number',
  star: 'star'
}

export const mediaTypeMap = {
  video: 'video',
  audio: 'audio',
  image: 'image'
}

export const questionAnswerConditionOptionsByQuestionType = {
  checkbox: [
    {
      value: questionAnswerConditionMap.CONTAINS,
      label: 'Содержит опцию'
    },
    {
      value: questionAnswerConditionMap.NOT_CONTAINS,
      label: 'Не содержит опцию'
    }
  ],
  radio: [
    {
      value: questionAnswerConditionMap.CONTAINS,
      label: 'Содержит опцию'
    },
    {
      value: questionAnswerConditionMap.NOT_CONTAINS,
      label: 'Не содержит опцию'
    }
  ],
  text: [
    {
      value: questionAnswerConditionMap.CONTAINS,
      label: 'Содержит'
    },
    {
      value: questionAnswerConditionMap.NOT_CONTAINS,
      label: 'Не содержит'
    }
  ],
  textarea: [
    {
      value: questionAnswerConditionMap.CONTAINS,
      label: 'Содержит'
    },
    {
      value: questionAnswerConditionMap.NOT_CONTAINS,
      label: 'Не содержит'
    }
  ],
  rating: [
    {
      value: questionAnswerConditionMap.GT,
      label: 'Рейтинг больше'
    },
    {
      value: questionAnswerConditionMap.LT,
      label: 'Рейтинг меньше'
    },
    {
      value: questionAnswerConditionMap.GTE,
      label: 'Рейтинг больше или равен'
    },
    {
      value: questionAnswerConditionMap.LTE,
      label: 'Рейтинг меньше или равен'
    }
  ],
  date: [
    {
      value: questionAnswerConditionMap.GTE_YEARS,
      label: 'Разница от текущего времени в ГОДАХ больше или равна'
    },
    {
      value: questionAnswerConditionMap.LTE_YEARS,
      label: 'Разница от текущего времени в ГОДАХ меньше или равна'
    },
    {
      value: questionAnswerConditionMap.GTE,
      label: 'После даты или равно ей'
    },
    {
      value: questionAnswerConditionMap.LTE,
      label: 'До даты или равно ей'
    }
  ],
  yesno: [
    {
      value: questionAnswerConditionMap.EQUALS,
      label: 'Равно'
    },
    {
      value: questionAnswerConditionMap.NOT_EQUALS,
      label: 'Не равно'
    }
  ]
}
