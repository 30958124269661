import { ENTITY_CHANGEMENT_TYPES } from '@common/constants'
import createEntityChangementConfig2 from '@infrastructure/entityChangement/createEntityChangementConfig2'
import queryClient from '@infrastructure/reactQuery/queryClient'
import { invalidateQueryKeys } from '@infrastructure/reactQuery/utils'
import toast from '@infrastructure/toast/toast'

import { USER_ENTITY } from '../common/constants'
import { keys } from '../reactQuery'

export default createEntityChangementConfig2({
  entity: USER_ENTITY,
  config: [
    {
      changements: [ENTITY_CHANGEMENT_TYPES.created],
      listener: ({ meta }) => {
        toast.info({
          title: `New user ${meta?.email}`
        })
        queryClient.invalidateQueries(keys.overviews())
      }
    },
    {
      changements: [ENTITY_CHANGEMENT_TYPES.updated],
      listener: ({ meta, isInternal }) => {
        const keysData = []
        const { id, email } = meta?.user ?? {}
        if (id) {
          keysData.push(keys.detail(id))
        } else {
          keysData.push(keys.details())
        }
        if (email && !isInternal) {
          toast.info({
            title: 'Success'
          })
        }
        invalidateQueryKeys(keysData, queryClient)
      }
    },
    {
      changements: [ENTITY_CHANGEMENT_TYPES.deleted],
      listener: ({ meta }) => {
        const { id } = meta?.user ?? {}
        toast.info({
          title: 'Success'
        })
        queryClient.removeQueries(keys.detail(id))
      }
    },
    {
      changements: [
        ENTITY_CHANGEMENT_TYPES.created,
        ENTITY_CHANGEMENT_TYPES.updated,
        ENTITY_CHANGEMENT_TYPES.deleted
      ],
      listener: () => {
        queryClient.invalidateQueries(keys.overviews())
      }
    }
  ]
})
