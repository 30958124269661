import { lazy } from 'react'
import { Tag } from 'react-feather'

import { Routes } from '@infrastructure/navigation/routes'
import verticalNavigation from '@infrastructure/navigation/vertical'

const basePath = '/promocodes'

Routes.push({
  path: basePath,
  component: lazy(() => import('../components/PromocodesPage')),
  meta: {
    authRoute: true
  }
})

verticalNavigation.financesConfig.children.push({
  id: 'promocodes',
  title: 'Promocodes',
  icon: <Tag size={20} />,
  navLink: basePath
})
