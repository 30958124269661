import {
  Activity,
  CheckSquare,
  Copy,
  DollarSign,
  Hexagon,
  Home,
  Settings,
  Tag,
  Type,
  Users
} from 'react-feather'

const financesConfig = {
  id: 'finances',
  icon: <DollarSign size={20} />,
  title: 'Finances',
  children: []
}

const prototypesSection = {
  id: 'prototypes',
  title: 'Прототипи',
  icon: <Copy size={20} />,
  children: []
}

const automationsSection = {
  id: 'automations',
  title: 'Автоматизація',
  icon: <Settings size={20} />,
  children: []
}

const config = [
  {
    header: 'Home'
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <Home size={20} />,
    navLink: '/dashboard'
  },
  {
    header: 'Users'
  },
  {
    id: 'users',
    title: 'Users',
    icon: <Users size={20} />,
    navLink: '/users'
  },
  {
    id: 'user-analyses',
    icon: <Activity size={20} />,
    title: 'Analyses',
    navLink: '/user-analyses'
  },
  {
    header: 'Content'
  },
  {
    id: 'articles',
    title: 'Articles',
    icon: <Type size={20} />,
    children: [
      {
        id: 'articles',
        title: 'All Articles',
        navLink: '/articles'
      },
      {
        id: 'article-categories',
        title: 'Categories',
        navLink: '/article-categories'
      }
    ]
  },
  {
    id: 'analyses',
    title: 'Analyses',
    icon: <Activity size={20} />,
    children: [
      {
        id: 'markers',
        title: 'Markers',
        navLink: '/markers'
      },
      {
        id: 'marker-groups',
        title: 'Marker Groups',
        navLink: '/marker-groups'
      },
      {
        id: 'marker-categories',
        title: 'Marker Categories',
        navLink: '/marker-categories'
      },
      {
        id: 'marker-units',
        title: 'Marker Units',
        navLink: '/marker-units'
      },
      {
        id: 'panels',
        title: 'Panels',
        navLink: '/marker-panels'
      },
      {
        id: 'formulas',
        title: 'Formulas',
        navLink: '/formulas'
      }
    ]
  },
  {
    id: 'tags',
    title: 'Tags',
    icon: <Tag size={20} />,
    navLink: '/tags'
  },
  {
    id: 'surveys',
    title: 'Surveys',
    icon: <CheckSquare size={20} />,
    navLink: '/surveys'
  },
  {
    id: 'supplements',
    title: 'Supplements',
    icon: <Hexagon size={20} />,
    children: [
      {
        id: 'supplements',
        title: 'Supplements',
        navLink: '/supplements'
      },
      {
        id: 'doseUnits',
        title: 'Dose Units',
        navLink: '/supplement-dose-units'
      }
    ]
  },
  {
    id: 'todo-tasks',
    title: 'Tasks',
    navLink: '/todo-tasks'
  },
  {
    id: 'todo-whens',
    title: 'Whens',
    navLink: '/todo-whens'
  },
  prototypesSection,
  automationsSection,
  financesConfig
]

const verticalNavigation = {
  config,
  financesConfig,
  automationsSection,
  prototypesSection
}

export default verticalNavigation
