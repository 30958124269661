import { isEmpty, keyBy, uniqBy } from 'lodash-es'

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  previewList: []
}

const articlesSlice = createSlice({
  initialState,
  name: 'articles',
  reducers: {
    addPreviews: (state, action) => {
      if (Array.isArray(action.payload)) {
        const filteredPayload = action.payload.filter((item) => item.title)
        if (filteredPayload.length) {
          state.previewList = uniqBy(
            filteredPayload.concat(state.previewList),
            'id'
          )
        }
      }
    },
    addPreview: (state, action) => {
      if (!isEmpty(action.payload)) {
        state.previewList = state.previewList.concat(action.payload)
      }
    }
  }
})

export const articlesActions = articlesSlice.actions

export const articlesReducer = articlesSlice.reducer

export const selectArticlePreviewById = (id) => (state) =>
  id && state.articles.previewList.find((item) => item.id === id)

export const selectArticlePreviewList = (state) => state.articles.previewList

export const selectArticlePreviewMap = (state) =>
  keyBy(state.articles.previewList, 'id')
