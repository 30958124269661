import { serialize } from 'object-to-formdata'

import { camelizeRespDataKeys } from '@common/utils'
import recGroupBasisRepository from '@features/recGroupBasis/repository'
import restApi from '@infrastructure/api/restApi'

export const name = 'recommendationGroupPrototype'

export const recommendationName = 'recGrProtRecommendation'

export const todoName = 'recGrProtRecTodo'

export const intervalName = 'recGrProtRecTodoInterval'

export const getOverviews = ({
  page = undefined,
  pageLimit = undefined
} = {}) =>
  restApi
    .get('/admin/recommendation-group-prototypes', {
      params: {
        page,
        pageLimit
      }
    })
    .then(camelizeRespDataKeys)

export const getPreviews = ({
  search = undefined,
  page = undefined,
  pageLimit = undefined
} = {}) =>
  restApi
    .get('/admin/recommendation-group-prototypes/previews', {
      params: {
        search,
        page,
        pageLimit
      }
    })
    .then(camelizeRespDataKeys)

export const getDetailById = (id) =>
  restApi
    .get(`/admin/recommendation-group-prototypes/${id}`)
    .then(camelizeRespDataKeys)

export const create = (data) =>
  restApi.post(
    '/admin/recommendation-group-prototypes',
    serialize(data, {
      indices: true,
      noFilesWithArrayNotation: true
    })
  )

export const updateById = (id, data) =>
  restApi.put(
    `/admin/recommendation-group-prototypes/${id}`,
    serialize(data, {
      indices: true,
      noFilesWithArrayNotation: true
    })
  )

export const deleteById = (id) =>
  restApi.delete(`/admin/recommendation-group-prototypes/${id}`)

export const createGroupRecommendation = (id, data) =>
  restApi.post(
    `/admin/recommendation-group-prototypes/${id}/recommendations`,
    data
  )

export const updateRecommendationById = (id, data) =>
  restApi.put(
    `/admin/recommendation-group-prototypes/recommendations/${id}`,
    data
  )

export const deleteRecommendationById = (id) =>
  restApi.delete(`/admin/recommendation-group-prototypes/recommendations/${id}`)

export const reorderGroupRecommendations = (id, data) =>
  restApi.patch(
    `/admin/recommendation-group-prototypes/${id}/recommendations/reorder`,
    data
  )

export const createRecTodo = (id, data) =>
  restApi.post(
    `/admin/recommendation-group-prototypes/recommendations/${id}/todos`,
    data
  )

export const updateTodoById = (id, data) =>
  restApi.put(
    `/admin/recommendation-group-prototypes/recommendations/todos/${id}`,
    data
  )

export const deleteTodoById = (id) =>
  restApi.delete(
    `/admin/recommendation-group-prototypes/recommendations/todos/${id}`
  )

export const createTodoInterval = (id, data) =>
  restApi.post(
    `/admin/recommendation-group-prototypes/recommendations/todos/${id}/intervals`,
    data
  )

export const reorderTodoIntervals = (id, data) =>
  restApi.patch(
    `/admin/recommendation-group-prototypes/recommendations/todos/${id}/intervals/reorder`,
    data
  )

export const updateIntervalById = (id, data) =>
  restApi.put(
    `/admin/recommendation-group-prototypes/recommendations/todos/intervals/${id}`,
    data
  )

export const deleteIntervalById = (id, data) =>
  restApi.delete(
    `/admin/recommendation-group-prototypes/recommendations/todos/intervals/${id}`,
    data
  )

export const defaultCValues = {
  ...recGroupBasisRepository.defaultCValues,
  note: ''
}

export const defaultEValues = {
  ...recGroupBasisRepository.defaultEValues,
  note: ''
}

export const transformToEFormData = (data) =>
  recGroupBasisRepository.transformToEFormData(data, {
    defaultValues: defaultEValues
  })
