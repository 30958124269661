import { format } from 'date-fns'
import { keyBy } from 'lodash-es'
import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { DATE_FORMATS } from '@common/constants'
import { byId, getDataProp, getRespData, getUrlFilename } from '@common/utils'
import { useGetMarkerCategoryPreviewListQ } from '@features/analysesSection/markerCategory/service/hooks'
import { toastApiError, toastApiResponseQOptions } from '@src/utility/apiUtils'

import { getDatePreviews, getOverviews, getPreviews } from '../repository'
import userAnalysesApi from '../service/userAnalysesApi'

export const keys = {
  all: ['userAnalyses'],
  lists: () => [...keys.all, 'lists'],
  previews: ({ query } = {}) => [...keys.lists(), 'previews', query],
  datePreviews: ({ query } = {}) => [...keys.lists(), 'datePreviews', query],
  overviews: (query = {}) => [...keys.all, 'overviews', query],
  details: () => [...keys.all, 'details'],
  detail: (id) => [...keys.details(), id],
  overview: (id) => [...keys.all, 'overview', id],
  documents: () => [...keys.all, 'documents'],
  detailDocuments: (detailId) => [...keys.documents(), detailId],
  detailDocument: (detailId, filename) => [
    ...keys.detailDocuments(detailId),
    filename
  ]
}

export const useDatePreviewsQ = ({ query, ...options } = {}) =>
  useQuery({
    queryKey: keys.datePreviews({ query }),
    queryFn: () => getDatePreviews({ query }).then(getDataProp),
    ...options
  })

export const usePreviewsQ = ({ query, ...options } = {}) =>
  useQuery({
    queryKey: keys.previews({ query }),
    queryFn: () => getPreviews({ query }).then(getDataProp),
    ...options
  })

export const useMakeDatePreviewTitle = (query) => {
  const { isLoading: categoriesLoading, data: categoryById } =
    useGetMarkerCategoryPreviewListQ({
      select: byId
    })
  const { isLoading: analysesLoading, data: userAnalysesById } = usePreviewsQ({
    query,
    select: byId
  })
  return useCallback(
    (data) =>
      `${
        categoriesLoading || analysesLoading
          ? '...'
          : categoryById?.[userAnalysesById[data.analysesId].categoryId]
              ?.title ?? '???'
      } від ${format(data.takenDate, DATE_FORMATS.DATE)}`,
    [analysesLoading || categoriesLoading, categoryById, userAnalysesById]
  )
}

export const useOverviewsQ = ({ query, ...options } = {}) =>
  useQuery({
    queryKey: keys.overviews(query),
    queryFn: () => getOverviews({ query }).then(getDataProp),
    ...options,
    ...toastApiResponseQOptions
  })

export const useGetUserAnalysesOverviewByIdQ = (id, options) =>
  useQuery(
    keys.overview(id),
    () => userAnalysesApi.getOverviewById(id).then(getRespData),
    options
  )

export const useGetUserAnalysesByIdQ = (id, options) =>
  useQuery(
    keys.detail(id),
    () =>
      userAnalysesApi.getAnalysesDetailById(id).then((resp) => {
        const {
          data: {
            userAnalyses: { panelMarkerResults, documents, ...rest },
            panels,
            units,
            markers
          }
        } = resp
        const panelById = keyBy(panels, 'id')
        const markerById = keyBy(markers, 'id')
        const unitById = keyBy(units, 'id')
        return {
          ...rest,
          documents: documents.map((item) => ({
            ...item,
            filename: getUrlFilename(item.fileUrl)
          })),
          panelMarkerResults: panelMarkerResults.map(
            ({ panelId, markerResults }) => ({
              panel: panelById[panelId] ?? null,
              markerResults: markerResults.map(
                ({ markerId, unitId, ...rest }) => {
                  const marker = markerById[markerId]
                  const units = marker.units.map(({ unitId }) => ({
                    unit: unitById[unitId]
                  }))
                  return {
                    ...rest,
                    marker: {
                      ...marker,
                      units
                    },
                    unit: unitById[unitId]
                  }
                }
              )
            })
          )
        }
      }),
    options
  )

export const useAssignUserAnalysesM = () => {
  const queryClient = useQueryClient()
  return useMutation((data) => userAnalysesApi.assignAnalyses(data), {
    onSuccess: (data, { userId }) => {
      queryClient.invalidateQueries(['users', 'analyses', userId])
      queryClient.invalidateQueries('usersAnalyses')
    }
  })
}

export const useUpdateUserAnalysesM = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, data }) => userAnalysesApi.updateAnalysesById(id, data),
    {
      onSuccess: (data, { id }) => {
        queryClient.invalidateQueries(keys.overviews())
        queryClient.invalidateQueries(keys.detail(id))
      }
    }
  )
}

export const useUpdateUserAnalysesMarkerResultsM = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...rest }) => userAnalysesApi.updateMarkerResults(id, rest),
    {
      onSuccess: (data, { id }) => {
        queryClient.invalidateQueries('usersAnalyses')
        queryClient.invalidateQueries(['usersAnalyses', id])
      },
      onError: (err) => {
        toastApiError(err)
      }
    }
  )
}

export const useSetIsAnalysesExaminedM = (options) => {
  const queryClient = useQueryClient()
  return useMutation(({ id, is }) => userAnalysesApi.setIsExamined(id, is), {
    ...options,
    onMutate: async ({ id, is }) => {
      const rQKey = keys.detail(id)
      const oldData = queryClient.getQueryData(rQKey)
      if (!oldData) {
        return { isReplaced: false }
      }
      await queryClient.cancelQueries({ queryKey: rQKey })
      queryClient.setQueryData(rQKey, {
        ...oldData,
        examinedAt: is ? new Date().toISOString() : null
      })
      return { oldData, rQKey }
    },
    onError: (err, _, context) => {
      const { oldData, rQKey } = context
      queryClient.setQueryData(rQKey, oldData)
      toastApiError(err)
    }
  })
}

export const useDeleteUserAnalysesByIdM = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => userAnalysesApi.deleteAnalysesById(id), {
    onSuccess: (data, { id }) => {
      queryClient.setQueryData('usersAnalyses', (oldData) =>
        oldData.filter((item) => item.id !== id)
      )
      queryClient.removeQueries(['usersAnalyses', id], { exact: true })
    }
  })
}

export const useDeleteUserAnalysesDocumentM = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ analysesId, filename }) =>
      userAnalysesApi.deleteAnalysesDocument(analysesId, filename),
    {
      onSuccess: (data, { analysesId, filename }) => {
        queryClient.removeQueries(keys.detailDocument(analysesId, filename), {
          exact: true
        })
        queryClient.setQueryData(keys.detail(analysesId), (oldData) => {
          oldData.map((item) => {
            if (item.id === analysesId) {
              item.documents = item.documents.filter(
                (file) => file !== filename
              )
            }
            return item
          })
        })
      }
    }
  )
}

const userAnalysesRQ = {
  useOverviewsQ,
  useDatePreviewsQ,
  useMakeDatePreviewTitle
}

export default userAnalysesRQ
