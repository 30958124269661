import toast from '@infrastructure/toast/toast'

export const notifyApiError = (toast, error) => {
  switch (error.response.status) {
    case 304: {
      toast.info({
        title: error.response.statusText,
        message: error.response.data?.error
      })
      break
    }
    default: {
      toast.error({
        title: error.response.statusText,
        message: error.response.data?.error
      })
    }
  }
}

export const toastApiError = (error) => {
  switch (error.response.status) {
    default: {
      toast.error({
        title: error.response.statusText,
        message: error.response.data?.error
      })
    }
  }
}

export const notifyApiSuccess = (toast, response) => {
  switch (response.status) {
    case 201: {
      toast.info({
        title: response.statusText,
        message: response.data?.message
      })
      break
    }
    default: {
      toast.error({
        title: 'Success',
        message: response.data?.message
      })
    }
  }
}

const toastErrCb = (error) => {
  toast.error({
    title: error.response.statusText,
    message: error.response.data?.error
  })
}

export const toastApiResponseMOptions = {
  onSuccess: (response) => {
    toast.success({
      title: response.status === 204 ? 'Success' : response.statusText,
      message: response.data?.message
    })
  },
  onError: toastErrCb
}

export const toastApiResponseQOptions = {
  onError: toastErrCb
}
