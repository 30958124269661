import { property } from 'lodash-es'
import { useMutation, useQuery } from 'react-query'

import { toastApiResponseMOptions } from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  create,
  deleteById,
  entityName,
  getList,
  updateById
} from '../repository'

export const keys = {
  all: [entityName],
  lists: () => [...keys.all, 'lists'],
  list: () => [...keys.lists(), 'list'],
  details: () => [...keys.all, 'detail'],
  detail: (id) => [...keys.details(), id]
}

const getManyProp = property('data.promocodes')

export const useListQ = () =>
  useQuery(keys.list(), () => getList().then(getManyProp))

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })
