import { groupBy } from 'lodash-es'
import { useMutation, useQuery } from 'react-query'

import { getDataProp } from '@common/utils'
import recommendationBasisRepository from '@features/recommendationBasis/repository'
import {
  toastApiResponseMOptions,
  toastApiResponseQOptions
} from '@src/utility/apiUtils'
import { mergeMutationCbProps } from '@utils'

import {
  create,
  createRecTodo,
  createTodoInterval,
  deleteById,
  deleteIntervalById,
  deleteTodoById,
  getDetailById,
  getOverviews,
  getPreviews,
  name,
  reorderTodoIntervals,
  updateById,
  updateIntervalById,
  updateTodoById
} from '../repository'

export const keys = {
  all: [name],
  lists: () => [...keys.all, 'lists'],
  overviews: () => [...keys.lists(), 'overviews'],
  previews: () => [...keys.lists(), 'previews'],
  details: () => [...keys.lists(), 'details'],
  detail: (id) => [...keys.details(), id]
}

const selectArrDataTree = (data) => {
  const { recommendationPrototypes, todos, todoIntervals } = data
  const todosByRecommendationId = groupBy(todos, 'recommendationId')
  const intervalsByTodoId = groupBy(todoIntervals, 'todoId')
  return recommendationPrototypes.map((recommendation) =>
    recommendationBasisRepository.createDataTree({
      recommendation,
      todosByRecommendationId,
      intervalsByTodoId
    })
  )
}

const selectDataTree = (data) => {
  const { recommendationPrototype, todos, todoIntervals } = data
  const todosByRecommendationId = groupBy(todos, 'recommendationId')
  const intervalsByTodoId = groupBy(todoIntervals, 'todoId')
  return recommendationBasisRepository.createDataTree({
    recommendation: recommendationPrototype,
    todosByRecommendationId,
    intervalsByTodoId
  })
}

export const usePreviewsQ = (options) =>
  useQuery({
    queryKey: keys.previews(),
    queryFn: () => getPreviews().then(getDataProp),
    select: selectArrDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useOverviewsQ = (options) =>
  useQuery({
    queryKey: keys.overviews(),
    queryFn: () => getOverviews().then(getDataProp),
    select: selectArrDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useDetailByIdQ = (id, options) =>
  useQuery({
    queryKey: keys.detail(id),
    queryFn: () => getDetailById(id).then(getDataProp),
    select: selectDataTree,
    ...options,
    ...toastApiResponseQOptions
  })

export const useCreateM = (options) =>
  useMutation({
    mutationFn: ({ data }) => create(data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateRecTodoM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createRecTodo(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateTodoById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteTodoByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteTodoById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useCreateTodoIntervalM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => createTodoInterval(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useUpdateIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => updateIntervalById(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useDeleteIntervalByIdM = (options) =>
  useMutation({
    mutationFn: ({ id }) => deleteIntervalById(id),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })

export const useReorderTodoIntervalsM = (options) =>
  useMutation({
    mutationFn: ({ id, data }) => reorderTodoIntervals(id, data),
    ...options,
    ...mergeMutationCbProps(toastApiResponseMOptions, options)
  })
