import restApi from '@infrastructure/api/restApi'

export const name = 'todoTask'

export const create = (data) => restApi.post('/admin/todo-tasks', data)

export const getOverviews = () => restApi.get(`/admin/todo-tasks`)

export const getPreviews = () => restApi.get(`/admin/todo-tasks/previews`)

export const getDetailById = (id) => restApi.get(`/admin/todo-tasks/${id}`)

export const updateById = (id, data) =>
  restApi.put(`/admin/todo-tasks/${id}`, data)

export const deleteById = (id) => restApi.delete(`/admin/todo-tasks/${id}`)
