import { upperFirst } from 'lodash-es'

import { ENTITY_CHANGEMENT_TYPES } from '@common/constants'
import { dotsJoin } from '@utils'

import { entityChangementEE } from './constants'

export const getEntityChangementEventName = (entity, changement) =>
  dotsJoin(entity, changement)

export const getEntityChangementEventListener = ({
  emitUpdatedEventOptions = undefined,
  listener = undefined
}) =>
  emitUpdatedEventOptions?.enabled
    ? (data, context, ...args) => {
        context = {
          ...context,
          isInternal: true
        }
        listener?.(data, ...args)
        entityChangementEE.emit(
          getEntityChangementEventName(
            emitUpdatedEventOptions.entity,
            ENTITY_CHANGEMENT_TYPES.updated
          ),
          ...[
            emitUpdatedEventOptions.processData?.(data, context) ?? data,
            context,
            ...args
          ]
        )
      }
    : listener

export const setEntityChangementListeners = (
  entity,
  changements,
  { emitUpdatedEventOptions = undefined, listener = undefined }
) => {
  for (const changement of changements) {
    if (emitUpdatedEventOptions?.enabled || listener) {
      entityChangementEE.addListener(
        getEntityChangementEventName(entity, changement),
        getEntityChangementEventListener({ listener, emitUpdatedEventOptions })
      )
    }
  }
}

export const getEntityChangementMessage = (name, type) =>
  `${upperFirst(name.replace(/_/, ' '))} has been ${type}`
