import { isNil } from 'lodash-es'

import { fromDefaultValues } from '@utils'

export const typeMap = {
  task: 'task',
  supplement: 'supplement'
}

export function makeIntervalPreview({
  order,
  pauseD,
  performD,
  isOrderEnabled = true
}) {
  const parts = []
  if (isOrderEnabled && !isNil(order)) {
    parts.push(`${order + 1}.`)
  }
  if (pauseD) {
    parts.push(`Пауза днів: ${pauseD}, потім`)
  }
  parts.push(`Виконувати днів: ${performD}`)
  return parts.join(' ')
}

export function createDataTree({ todo, intervalsByTodoId }) {
  return {
    ...todo,
    intervals: intervalsByTodoId?.[todo.id] ?? []
  }
}

export const defaultEValues = {
  type: null,
  priority: NaN,
  asSupplement: null,
  asTask: null,
  whens: []
}

export const defaultCValues = {
  ...defaultEValues,
  intervals: []
}

export const intervalDefaultValues = {
  pauseD: 0,
  performD: NaN
}

export const transformToIntervalCEFormData = (data) =>
  fromDefaultValues(data, intervalDefaultValues)

export const transformToCFormData = (data) => {
  const formData = fromDefaultValues(data, defaultCValues)
  return {
    ...formData,
    intervals: formData.intervals.map(transformToIntervalCEFormData)
  }
}

const todoBasisRepository = {
  typeMap,
  createDataTree,
  makeIntervalPreview,
  defaultEValues,
  defaultCValues,
  transformToIntervalCEFormData,
  transformToCFormData
}

export default todoBasisRepository
